import React, { useContext } from "react";
import {
  bearlog,
  BearDiv,
  BearBalance,
  BearFloat,
  BearTitle,
  BearList,
  capitalizeFirstLetter,
  BearDivMain,
} from "@sillyscribe95/bear-ui-web";
import { AiOutlineAppstore } from "react-icons/ai";
import { IntegrationsMain } from "../integrations/integrations-main";
import { TriggersContext } from "./context/triggers-context";
import { TriggerStepChooseContainer } from "./trigger-step-choose-container";
import { getUserIntegrations } from "../integrations/data/trigger-integration-options-data";
import { BsArrowBarRight, BsArrowCounterclockwise } from "react-icons/bs";
import { TriggerStepChooseTypeSearch } from "./trigger-step-choose-type-search";

export function TriggerStepChooseType(data) {
  const nsadaw =
    //
    TriggerStepChooseTypeSearch(data);

  return nsadaw;
}
