import { BearColors } from "@sillyscribe95/bear-ui-web";
import React, { useState } from "react";
import { BearFormSubmit } from "./bear-form-submit-hooks";
// import { BearCheckMain, BearColors } from "../..";

export function BearFormBasic({
  id,
  bearName = "",
  inputConfig,
  formFieldsConfig,
  //
  // 1button
  renderButton,
  noButton,
  noButtonEnd,
  buttonConfig = {},
  buttonText = "Submit",
  buttonSize = "35px",
  //
  // 1required
  requireAll,
  //
  // 1submit
  onSubmit,
  disableSubmit,
  submitDisplay,
  submitExtra,

  //
  //
  style,
  children,
  ...args
}) {
  id = id ? id : bearName;
  //

  const baseargs = {
    onSubmit,
  };

  const completeArgs = {
    bearName,
    ...baseargs,
    ...args,
  };

  // 1onsubmit
  function onSubMain(event) {
    BearFormSubmit({ event, ...baseargs });
  }

  // 1button
  function Buttiona({ disabledConfig, disabled, text, style, ...siwerew }) {
    disabled = disableSubmit || disabled;

    const jsadcvx = {
      ...BearColors("white", "green"),
      padding: "5px 10px",
      margin: "5px 10px",
      fontSize: buttonSize,
      // marginBottom: "20px",
      ...style,
    };

    const dvbijkrw = {
      ...siwerew,
      type: "submit",
      value: text,
      form: id,
      style: jsadcvx,
    };

    let adhwdse = (
      <button {...dvbijkrw}>
        {/*  */}
        {buttonText}
      </button>
    );

    return adhwdse;
  }

  const sdnfer = {
    onSubmit: onSubMain,
    id,
    style: {
      position: "relative",
      ...style,
    },
  };

  const checkArgs = {
    ...completeArgs,
    requiredArgs: baseargs,
  };

  const goBut = !noButton && Buttiona(buttonConfig);

  const xcvsfs = (
    <>
      <form {...sdnfer}>
        <div {...formFieldsConfig}>{children}</div>
        {children && goBut}
      </form>
    </>
  );

  return xcvsfs;
}
