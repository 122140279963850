import React from "react";
import {
  bearlog,
  BearDiv,
  BearBalance,
  BearLine,
  BearList,
} from "@sillyscribe95/bear-ui-web";
import { getInputTypes } from "./const/input-const";

export function InputOptionTypes(ext) {
  const dateBox = {};

  const dictvar = getInputTypes();

  const list = Object.values(dictvar);

  bearlog.lug(`___ list ___`, list);

  function renderItem(basjwe, index) {
    bearlog.lug(`___ basjwe ___`, basjwe);
    const { icon, text } = basjwe;

    const mains = index + 1 !== list.length;

    return BearBalance(icon, text);
  }

  const itemConfig = {
    className: "shadowMain buttonHover",
    style: { padding: "3px 10px" },
  };

  const okfdsd = BearList({
    list,
    renderItem,
    itemConfig,
    ...ext,
    // renderItem: (maino) =>
    //   BearList({
    //     list: maino,
    //     renderItem,
    //   }),
  });

  return okfdsd;
}
