import React, { useState } from "react";
import {
  bearlog,
  BearDiv,
  BearBalance,
  BearList,
  BearDivMain,
  BearTitle,
  BearColors,
  BearFloat,
  BearHeight,
} from "@sillyscribe95/bear-ui-web";
import { DashboardPage } from "../dashboard-page";
import { TriggersContext } from "./context/triggers-context";
import { useContext } from "react";
import { TriggerMainContainer } from "./trigger-main-container";

export function TriggerTestContainer({ id, option }) {
  const { triggerData } = useContext(TriggersContext);
  const [runTrue, setrunTrue] = useState();
  const testData = triggerData;
  const { name, testResponse: list } = testData;

  function runBase() {
    return runTrue ? "Loading..." : `Run ${name} with test data?`;
  }

  function doRun() {
    setrunTrue(true);
  }

  function fieldBase() {
    function renderItem({ key, value }) {
      return BearBalance(key, value);
    }

    return runTrue
      ? ""
      : BearList({
          list,
          renderItem,
        });
  }

  const title = runBase();
  const subtitle = fieldBase();

  const center = BearTitle({
    title,
    subtitle,
    align: "center",
    spaceBetween: "10vh",
    style: { height: "40vh" },
  });

  let bottom = BearDivMain("Run Test", {
    className: "shadowMain buttonHover",
    fontSize: "30px",
    ...BearColors("white", "background"),
    onClick: doRun,
    style: { padding: "10px 20px" },
  });
  bottom = BearFloat({
    center: bottom,
  });

  const okfdsd = (
    <>
      {center}
      {bottom}
    </>
  );

  return TriggerMainContainer("test", okfdsd);
}
