import {
  BearBalance,
  BearList,
  bearlog,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import { capitalize } from "lodash";
import { useContext } from "react";
import { TriggersContext } from "./context/triggers-context";
import { TriggerStepChooseContainer } from "./trigger-step-choose-container";
import { triggerStepExternalOptionsData } from "./trigger-step-external-options-data";

export function TriggerStepChooseFunction(data, setstepViewType) {
  const { editStepinTrigger } = useContext(TriggersContext);

  const { integrationData } = data;
  const { icon, name, integrationSlug, integrationType } = integrationData;
  // const

  const sjdnfw =
    //
    // "chooseType"
    "chooseIntegration";

  const right = BearBalance(icon, name, {
    onClick: () => setstepViewType(sjdnfw),
    className: "shadowMain buttonHover",
  });

  const list =
    //
    triggerStepExternalOptionsData[integrationSlug];
  // triggerInputExternalOptionsData[gsheets]

  function renderItem({ name: title, description: subtitle }) {
    return BearTitle({
      title,
      subtitle,
      align: "left",
    });
  }

  const itemConfig = {
    className: "shadowMain buttonHover",
    style: { padding: "10px" },
  };

  function click(stepData) {
    bearlog.lug(`___ prevData ___`, { stepData, data });

    const newData = {
      ...data,
      integrationData: {
        ...integrationData,
        stepData,
      },
    };

    bearlog.lug(`___ newData ___`, newData);

    //
    editStepinTrigger(newData);
    setstepViewType("main");
  }

  const spaceBetween = "20px";

  const subtitle = BearList({
    list,
    renderItem,
    itemConfig,
    click,
    spaceBetween,
  });

  const nasd = `${capitalize(integrationType)}`;

  const okfdsd = TriggerStepChooseContainer(subtitle, nasd, {
    right,
    backType: "main",
  });

  return okfdsd;
}
