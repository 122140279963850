import React from "react";
import {
  bearlog,
  BearDiv,
  BearFloat,
  BearDivMain,
  BearTitle,
  BearBalance,
  capitalizeFirstLetter,
  BearColors,
} from "@sillyscribe95/bear-ui-web";
import { SidebarIcons } from "../components/sidebar/sidebar-icons";
import { useHistory } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import { PageContainer } from "./PageContainer";
import { capitalize } from "lodash";
import { backColor, mobView } from "../const/main-const";
import { BearPage } from "../../bear/page/bear-page";
import { Input } from "antd";

export function DashboardPage(typeo, center, other = "") {
  const {
    id,
    typeFetch,
    backTrue,
    editTitle,
    addNew,
    title,
    titleConfig,
    fixTitle,
  } = other;

  const history = useHistory();

  let sidecons = SidebarIcons(typeo);

  const newTrue = id === "new";
  const mainso = center;
  const main =
    //
    typeo;
  // capitalizeFirstLetter(typeo);

  // 1title
  function rendTitle() {
    let titleBase = title ? title : addNew ? `New ${main}` : main;

    titleBase = editTitle
      ? Input({
          defaultValue: titleBase,
        })
      : titleBase;

    function backGo() {
      history.goBack();
    }

    const bakcIonc =
      //
      IoMdArrowBack();
    // IoArrowBackCircleOutline();

    const showBack = backTrue || id;

    const backButton =
      showBack &&
      BearDivMain(bakcIonc, {
        style: {
          marginTop: "10px",
          marginRight: "20px",
        },
        className: "pointer expandHover",
        onClick: backGo,
      });

    let left = BearBalance(backButton, titleBase);

    const style = fixTitle && {
      // marginBottom: "100px",
      background: "white",
      borderBottom: "1px solid black",
      position: "fixed",
      width: "100vw",
      top: 0,
      left: 0,
      padding: "20px 20px 10px",
    };

    return BearFloat({
      style,
      // fontSize: "22px",
      left,
      ...titleConfig,
    });
  }

  center = BearTitle({
    title: rendTitle(),
    lineBetween: true,
    subtitle: mainso,
    style: mobView
      ? {
          width: "98vw",
          padding: "10px",
          marginBottom: "200px",
        }
      : {
          align: "left",
          // 1left
          margin:
            //
            // "20px",
            "3vh 35vw 0 5vw",
          width: "40vw",
        },
  });

  const left =
    !mobView &&
    BearDivMain(sidecons, {
      style: {
        padding: "10vh 50px 0 20px",
        minHeight: "100vh",
        fontSize: "18px",
        background: backColor,
      },
    });

  const bottom =
    //
    mobView && sidecons;

  const children = BearFloat({
    left,
    bottom,
    center,
    vertAlign: false,
    // vertAlign: "center",
  });

  const differasds = mobView
    ? {
        style: { minHeight: "100vh" },
      }
    : {};

  return BearPage({
    children,
    ...differasds,
    fontSize: "30px",
  });
}
