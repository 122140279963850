import React from "react";
import {
  bearlog,
  BearDiv,
  BearDivMain,
  BearList,
  BearTitle,
  BearBalance,
  BearFloat,
} from "@sillyscribe95/bear-ui-web";
import { integrationsTypes } from "./data/integrations-types-data";
import { difference } from "lodash";
import { useHistory } from "react-router-dom";
import { userIntegrations } from "./data/integrations-user-data";
import { DashboardPage } from "../dashboard-page";

export function IntegrationsAddNew() {
  const { integrationList } = userIntegrations;
  let list = Object.keys(integrationsTypes);
  list = difference(list, integrationList);

  function renderItem({ type, icon, description }) {
    const bareIcon = BearBalance(icon, type, {
      //   a,
    });

    const title = BearFloat({
      left: bareIcon,
    });

    return BearTitle({
      align: "left",
      title,
      //   lineBetween: true,
      subtitle: description,
      style: { padding: "5px" },
    });
  }

  const history = useHistory();
  function click(data) {
    const { itemName } = data;
    bearlog.lug(`___ asfnsadi data ___`, data);
    history.push(
      //
      `new/${itemName}`
    );
  }

  const itemConfig = {
    align: "left",
    className: "shadowMain buttonHover",
  };

  const okfdsd = BearList({
    list,
    renderItem,
    dictvar: integrationsTypes,
    click,
    spaceBetween: "30px",
    itemConfig,
  });

  // // 1existing
  // function existBase(){
  //   const subtitle = BearList({
  //     integrationList
  //   })

  //   return BearTitle({
  //     title: "Your Integrations"
  //   })
  // }

  return DashboardPage("integrations", okfdsd, {
    addNew: true,
    backTrue: true,
    title: "New Integration",
  });
}
