import React from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearDivMain,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import { useHistory } from "react-router-dom";
import { TriggerStepChooseIntegration } from "../triggers/trigger-step-choose-integration";
import { TriggerPathEntry } from "../triggers/trigger-path-entry";
import { TriggerStepChooseFunction } from "../triggers/trigger-step-choose-function";
import { TriggerStepChooseContainer } from "../triggers/trigger-step-choose-container";
import { TriggerPathEdit } from "../triggers/trigger-path-edit";
import {
  newCustomerStripe,
  sendEmail,
  testStep,
  uploadSheets,
} from "../triggers/triggers-user-data";
import { SidebarIcons } from "../../components/sidebar/sidebar-icons";
import { DashboardPage } from "../dashboard-page";
import { TriggerStepEdit } from "../triggers/trigger-step-edit";

export function CompsPage(aaaaa) {
  const {
    //
    match: { params },
    ...args
  } = aaaaa;

  const history = useHistory();

  function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  }

  const { type, id } = params;

  const listMain = {
    "Page Template": () => DashboardPage(""),
    Sidebar: () => SidebarIcons(""),
    "New Step in Path": () => TriggerStepEdit({}),
    "Edit Trigger Step (Email)": () => TriggerStepEdit(sendEmail),
    "Edit Trigger Step (Sheets)": () => TriggerStepEdit(uploadSheets),
    "Edit Trigger Step (Payment)": () => TriggerStepEdit(newCustomerStripe),
  };

  const list = Object.keys(listMain);

  bearlog.lug(`___ list ___`, list);

  const listDict = {};
  const listSlug = {};

  Object.entries(listMain).map(function ([item, value]) {
    const key = string_to_slug(item);
    listSlug[item] = key;
    listDict[key] = value;
  });

  function goLIst() {
    //   1list

    function click(type) {
      const base = listSlug[type];
      bearlog.lug(`___ type ___`, { type, listSlug, base });

      history.push(`/comps/${base}`);
    }

    const itemConfig = {
      className: "shadowMain buttonHover",
    };

    const subtitle = BearList({
      list,
      click,
      spaceBetween: "20px",
      itemConfig,
    });

    const title = "Components";

    return BearTitle({
      lineBetween: true,
      title,
      subtitle,
      align: "left",
    });
  }

  const endos = id ? listDict[id]({}) : goLIst();

  return BearDivMain(endos, {
    style: { padding: "10% 20%" },
    align: "center",
    fontSize: "30px",
  });
}
