import React, { useEffect, useState } from "react";
import {
  bearlog,
  BearDiv,
  capitalizeFirstLetter,
  BearBalance,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import { Input } from "antd";
import { iconTriggi } from "../../const/main-const";

export function EnginePage(aaaaa) {
  const {
    //
    match: { params },
    ...args
  } = aaaaa;
  const { type } = params;

  const [loadInit, setloadInit] = useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      setloadInit(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  function asdjqw() {
    const nameBase = capitalizeFirstLetter(type);
    const asdkas = `Enter your ${nameBase} API key:`;

    function onSubmit(balua) {
      bearlog.lug(`___ balua ___`, balua);
      setloadInit();
    }

    const inpUconfi = {
      onSubmit,
    };

    return (
      <>
        {asdkas}
        <Input {...inpUconfi} />
      </>
    );
  }

  const subtitle = loadInit ? asdjqw() : "Loading...";

  const title = BearBalance(iconTriggi, "Triggi");

  return BearTitle({
    title,
    subtitle,
    spaceBetween: "40px",
    lineBetween: true,
    align: "center",
    fontSize: "30px",
    style: {
      padding: "200px 20% 0",
    },
  });
}
