import React, { useContext } from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearColors,
  BearBalance,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import { useState } from "react";
import { DashboardPage } from "../dashboard-page";
import { TriggersEditContainer } from "./triggers-edit-container";
import { testTriggger } from "./triggers-user-data";
import { TriggersContext } from "./context/triggers-context";
import { TriggersNewInputType } from "./triggers-new-input-type";

export function TriggersNewContainer({ option }) {
  const { triggerInputType, triggerData, settriggerData } =
    useContext(TriggersContext);

  const data =
    //
    // "";
    triggerData;
  // testTriggger;

  const { name = "New Trigger", input } = data;

  bearlog.lug(`___ input ___`, input);

  const newBase = input ? TriggersEditContainer(data) : TriggersNewInputType();

  const chooseBase = DashboardPage("triggers", newBase, {
    backTrue: true,
    title: name,
  });

  return chooseBase;
}
