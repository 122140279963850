import React from "react";
import {
  bearlog,
  BearDiv,
  BearTitle,
  BearList,
} from "@sillyscribe95/bear-ui-web";
import { newClientTrigger, testTriggger } from "./triggers-user-data";
import { DashboardPage } from "../dashboard-page";
import { TriggerPathEntry } from "./trigger-path-entry";
import { TriggerEditInput } from "./trigger-edit-input";
import { TriggersContext } from "./context/triggers-context";
import { useContext } from "react";
import { TriggerStepEdit } from "./trigger-step-edit";

export function TriggersEditContainer() {
  const { triggerData } = useContext(TriggersContext);

  bearlog.lug(`___ triggerData ___`, triggerData);

  const { input, path: list } = triggerData;

  function rendInput() {
    // const right = formData && BearDivMain()

    // const TriggerPathEntry()

    return TriggerEditInput(input);
  }

  const jqwASD = BearList({
    renderItem: (data) => TriggerPathEntry(data, TriggerStepEdit),
    list,
  });

  const okfdsd = (
    <>
      {rendInput()}
      {/*  */}
      {jqwASD}
    </>
  );

  return okfdsd;
}
