import { SiAirtable, SiGooglesheets } from "react-icons/si";

const gsheets = {
  name: "Google Sheets",
  icon: SiGooglesheets({
    color: "green",
  }),
  integrationSlug: "gsheets",
  integrationType: "sheets",
  apiPoint: "http://www.google.com",
};

const airtable = {
  name: "Airtable",
  icon: SiAirtable(),
  integrationSlug: "airtable",
  integrationType: "sheets",
  apiPoint: "http://www.google.com",
};

export const optionSheetsData = {
  gsheets,
  airtable,
};
