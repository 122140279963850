import React from "react";
import { bearlog, BearDiv } from "@sillyscribe95/bear-ui-web";
import { TriggersMain } from "./triggers-main";
import { TriggersNewContainer } from "./triggers-new-container";
import { TriggersEditData } from "./trigger-edit-data";
import TriggersContextProvider from "./context/triggers-context";
import { TriggerTestContainer } from "./trigger-test-container";

export function TriggerPage(aaaaa) {
  const {
    //
    match: { params },
    ...args
  } = aaaaa;

  const { id, option } = params;

  bearlog.lug(`___ params ___`, params);
  const newtrue = id == "new";
  const testMain = option == "test";

  bearlog.lug(`___ option ___`, option);

  function duno() {
    const eso = newtrue
      ? TriggersNewContainer
      : testMain
      ? TriggerTestContainer
      : id
      ? TriggersEditData
      : TriggersMain;

    return eso({
      id,
      option,
    });
  }
  // const addType = IntegrationNewOptions

  const eaws =
    //
    duno();

  // (
  //     <>
  //       <TriggersContextProvider>{duno()}</TriggersContextProvider>
  //     </>
  //   );

  return eaws;
}
