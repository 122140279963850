import React from "react";
import { bearlog, BearDiv, BearList } from "@sillyscribe95/bear-ui-web";
import { triggerInputExternalOptionsData } from "./trigger-input-external-options-data";
import { ItemCase } from "../../components/sidebar/item-case";
import { SiStripe } from "react-icons/si";
import { ItemList } from "../../components/sidebar/item-list";
import { useContext } from "react";
import { TriggersContext } from "./context/triggers-context";

export function TriggerInputExternalOptions() {
  const { settriggerData } = useContext(TriggersContext);

  function click(input) {
    //

    // bearlog.lug(`___ data ___`, data);
    settriggerData({ input });
  }

  const list =
    //
    triggerInputExternalOptionsData;

  function renderItem(main) {
    bearlog.lug(`___ main ___`, main);

    return ItemCase(main);
  }

  const okfdsd = ItemList({
    list,
    renderItem,
    click,
  });

  return okfdsd;
}
