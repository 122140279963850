import React from "react";
import { bearlog, BearDiv, BearDivMain } from "@sillyscribe95/bear-ui-web";
import { DashboardPage } from "../dashboard-page";

export function ExplorePage(aaaaa) {
  const {
    //
    match: { params },
    ...args
  } = aaaaa;

  const { id, option } = params;
  const esos = BearDivMain("Coming soon.", {
    style: { height: "200vh" },
  });

  return DashboardPage("explore", esos, {
    title: "Explore",
  });
}
