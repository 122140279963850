import { MdOutlineShortText } from "react-icons/md";
import { BsFillCloudUploadFill, BsTextParagraph } from "react-icons/bs";
import { GrMultiple } from "react-icons/gr";

export function getInputTypes(type) {
  const text = {
    icon: MdOutlineShortText(),
    text: "Text",
  };

  const paragraph = {
    icon: BsTextParagraph(),
    text: "Paragraph",
  };

  const multichoice = {
    icon: GrMultiple(),
    text: "Multi-Choice",
  };

  const fileUpload = {
    icon: BsFillCloudUploadFill(),
    text: "File Upload",
  };

  const decoc = {
    text,
    paragraph,
    //
    // multichoice,
    // fileUpload,
  };

  return type ? decoc[type] : decoc;
}
