import React from "react";
import {
  bearlog,
  BearDiv,
  BearDivMain,
  capitalizeFirstLetter,
} from "@sillyscribe95/bear-ui-web";
import { DashboardPage } from "../dashboard-page";
import { useHistory } from "react-router-dom";
import { capitalize } from "lodash";

export function DashboardTypeMain(a, b, ext = "") {
  const { clearFunc } = ext;
  const okfdsd = <>sssss</>;

  let history = useHistory();

  function addNew() {
    if (clearFunc) {
      clearFunc("");
    }

    history.push(`/${a}/new`);
  }
  const right = BearDivMain("+", {
    onClick: addNew,
    // align: "center",
    style: {
      padding: "0 20px",
      // width: "200px",
      // margin: "0 140px",
    },
    className: "shadowMain buttonHover",
  });

  const title = capitalizeFirstLetter(a);

  ext = {
    title,
    titleConfig: { right },
    ...ext,
  };

  return DashboardPage(a, b, ext);
}
