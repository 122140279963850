import React, { useContext } from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearBalance,
  BearFloat,
  BearDivMain,
} from "@sillyscribe95/bear-ui-web";
import { DashboardTypeMain } from "../dashboard/dashboard-type-main";
import { userTriggers } from "./triggers-user-data";
import { useHistory } from "react-router-dom";
import { ItemList } from "../../components/sidebar/item-list";
import { FaHandPointRight } from "react-icons/fa";
import { TriggersContext } from "./context/triggers-context";
import { TriggerStepOptionButton } from "./trigger-step-option-button";
import { IntegrationIconListComponent } from "../integrations/components/integration-icon-list-component";

export function TriggersMain() {
  const list = userTriggers;
  const { settriggerData } = useContext(TriggersContext);

  const spaceBetween = "20px";

  const history = useHistory();

  function click(base) {
    bearlog.lug(`___ base ___`, base);

    const { id } = base;

    settriggerData(base);
    history.push(`/triggers/${id}`);
  }

  function customRender({ left, ...data }) {
    const { trigger = "", path = [], uses } = data;

    function intRend() {
      let list = [
        //
        trigger,
        ...path,
      ].map(({ integrationData }) => integrationData);

      bearlog.lug(`___ list ___`, list);

      return IntegrationIconListComponent({
        list,
      });
    }

    const useBase = BearDivMain(`${uses} runs`, {
      className: "shadowMain",
      fontSize: "0.7em",
      minWidth: "50px",
    });

    const extoBase =
      //
      intRend();
    // useBase
    // BearBalance(intRend(), { padvar: "20px" });

    const right = BearBalance(extoBase, TriggerStepOptionButton(data));

    return BearFloat({
      //
      left,
      right,
    });
  }

  const okfdsd = ItemList({
    list,
    config: {
      customRender,
      includeDescription: true,
    },
    click,
  });

  // 1add

  return DashboardTypeMain("triggers", okfdsd, {
    clearFunc: settriggerData,
  });
}
