import React, { useEffect, useState, useContext } from "react";
import {
  BearDiv,
  BearDivMain,
  bearlog,
  bearMobileView,
} from "@sillyscribe95/bear-ui-web";
// import { BearFooter } from "../layout/BearFooter";
// import BearHeader from "../layout/BearHeader";
// import TitleMain from "./TitleMain";
import { BearMetaTags } from "./BearMetaTags";
import BearHeader from "./bear-header";

export function BearPage({
  //
  // 1helmet 1meta
  pageArgs,
  headerConfig,
  metaConfig,
  footer,
  footerConfig,
  heightBottom,
  title,
  titleConfig,
  description,
  image,
  className,
  children,
  customPadding,
  ...orga
}) {
  const sjd3as =
    title &&
    BearDiv({
      obj: title,
      align: "center",
      fontSize: "2.1em",
      ...titleConfig,
    });

  const sdifjwe = (
    <>
      {/*  */}
      {sjd3as}
      {children}
    </>
  );

  const paddos = customPadding ? customPadding : bearMobileView ? "2%" : "10%";
  const sdfwr = `2% ${paddos}`;

  const ijfer = {
    style: {
      minHeight: "100vh",
      // background: "#E9E5E3",
      // padding: sdfwr,
    },
    children: sdifjwe,
  };

  bearlog.lug(`___ headerConfig ___`, headerConfig);

  const aijewqe = (
    <>
      {/* Triggi */}
      {/* {BearHeader(headerConfig)} */}
      <div {...ijfer} />
    </>
  );

  bearlog.lug(`___ orga ___`, orga);

  return BearDivMain(aijewqe, orga);
}
