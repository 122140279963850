import { SiGoogleForms, SiDropbox } from "react-icons/si";

const gforms = {
  name: "Google Forms",

  numTriggers: 2,
  numActions: 10,
  integrationSlug: "google-forms",
  integrationType: "forms",
  // inputList: stripeInputs,
  // redirect:
};

export const optionsFormsData = {
  gforms,
};
