import React, { useState } from "react";
import { bearlog, BearDiv } from "@sillyscribe95/bear-ui-web";
import { Divider, Input, Popover } from "antd";
import { TriggersContext } from "./context/triggers-context";
import { backColor, mobView } from "../../const/main-const";
import { TriggerStepOptionButton } from "./trigger-step-option-button";
import { TriggerStepFields } from "./trigger-step-fields";
import {
  TriggerStepMiddle,
  TriggerStepMiddleTest,
} from "./trigger-step-middle";
import { useContext } from "react";
import { TriggerStepChooseContainer } from "./trigger-step-choose-container";
import { TriggerStepChooseFunction } from "./trigger-step-choose-function";
import { TriggerStepChooseIntegration } from "./trigger-step-choose-integration";
import { TriggerStepChooseType } from "./trigger-step-choose-type";
import { FaQuestion } from "react-icons/fa";
import { TriggerStepPanelComponent } from "../integrations/components/trigger-step-panel-component";
import { TriggerPathEntry } from "./trigger-path-entry";

export function TriggerStepEdit(data) {
  const { stepViewType, setstepViewType } = useContext(TriggersContext);
  const { fields, integrationData = "" } = data;
  const { currentAccount, icon, integrationType } = integrationData;

  // const [stepViewType, setstepViewType] = useState("main");

  function chooseBase() {
    const vierwBase = {
      main: TriggerStepMiddle,
      // main: TriggerStepMiddle,
      chooseIntegration: TriggerStepChooseIntegration,
      chooseType: TriggerStepChooseType,
      chooseFunc: TriggerStepChooseFunction,
    }[stepViewType];

    return vierwBase(data, setstepViewType);
  }

  const njkdfwe =
    //
    integrationData;
  // stepViewType || integrationData;

  let mainBase =
    //
    njkdfwe
      ? chooseBase()
      : //
        // TriggerStepChooseContainer(data);
        // TriggerStepChooseIntegration(data);
        TriggerStepChooseType(data);

  return mainBase;
}
