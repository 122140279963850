import { SiPaypal, SiStripe } from "react-icons/si";

const stripe = {
  name: "Stripe",
  icon: SiStripe({
    color: "blue",
  }),
  description:
    "Stripe is a developer-friendly way to accept payments online and in mobile apps. Stripe's suite of APIs powers commerce for thousands of companies of all sizes, processing billions of dollars for businesses each year.",
  apiPoint:
    "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_LidnVChWUvpIzYYhWFbJrLzXmQ0Jb0Ef&scope=read_write",
  numTriggers: 4,
  numActions: 20,
  integrationSlug: "stripe",
  integrationType: "payment",
  // inputList: stripeInputs,
  // redirect:
};

const paypal = {
  name: "Paypal",
  icon: SiPaypal({
    color: "purple",
  }),
  description:
    "PayPal is a fast, safe way to send money, make an online payment, receive money or set up a merchant account. With over 150 million active registered accounts, PayPal helps people and businesses receive and make payments in more than 100 currencies in 203 markets.",
  // apiPoint: ""
  numTriggers: 2,
  numActions: 10,
};

export const optionPaymentsData = {
  stripe,
  paypal,
};
