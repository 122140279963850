import React, { useContext, useEffect } from "react";
import { bearlog, BearDiv } from "@sillyscribe95/bear-ui-web";
import { useQuery } from "react-query";
import { TriggersEditContainer } from "./triggers-edit-container";
import { newClientTrigger, testTriggger } from "./triggers-user-data";
import { TriggersContext } from "./context/triggers-context";
import { DashboardPage } from "../dashboard-page";
import { newClientInput } from "../inputs/input-user-data";
import { TriggerMainContainer } from "./trigger-main-container";

export function TriggersEditData({ id }) {
  const { triggerData, settriggerData } = useContext(TriggersContext);

  const { name } = triggerData;

  bearlog.lug(`___ TriggersEditData ___`, triggerData);

  function dataGo() {
    const data =
      //
      testTriggger;

    bearlog.lug(`___ testTriggger ___`, testTriggger);

    settriggerData(data);
  }

  useEffect(() => {
    // triggerData,

    dataGo();
  }, []);

  const nsdeqw =
    //
    TriggersEditContainer(triggerData);
  // triggerData ? TriggersEditContainer(triggerData) : "LOADING";

  const ksdw =
    //
    // "asdlqwe";
    TriggerMainContainer("adfa", nsdeqw);

  return ksdw;
}
