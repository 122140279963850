import {
  BearBalance,
  BearFloat,
  BearList,
  bearlog,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import Input from "antd/lib/input/Input";
import { useContext } from "react";
import { AiOutlineAppstore } from "react-icons/ai";
import { BsArrowBarRight } from "react-icons/bs";
import { TriggersContext } from "./context/triggers-context";
import { TriggerStepChooseContainer } from "./trigger-step-choose-container";
import { testChooseStepOptions } from "./trigger-step-external-options-data";

export function TriggerStepChooseTypeSearch(data, setstepViewType) {
  const { integrationData } = data;
  const { editStepinTrigger } = useContext(TriggersContext);

  function rightBase() {
    const apps = {
      icon: AiOutlineAppstore(),
      name: "Apps",
      newBase: "chooseIntegration",
      subtitle: "Choose an integration from our wide list of apps.",
    };

    const sendAndRecieve = {
      icon: BsArrowBarRight(),
      name: "Send",
      subtitle: "Turn the data you receive into something else.",
    };

    const itemConfig = {
      className: "shadowMain buttonHover",
    };

    const list = [
      //
      sendAndRecieve,
      apps,
    ];

    function click(baseos) {
      bearlog.lug(`___ baseos ___`, baseos);

      const { newBase } = baseos;
      if (newBase) {
        setstepViewType(newBase);
      }
    }

    return BearList({
      list,
      itemConfig,
      spaceBetween: "5px",
      renderItem: ({ icon, name }) => BearBalance(icon, name),
      horiz: true,
      click,
    });
  }

  const right = rightBase();

  function click({ name, description, integrationData, ...ext }) {
    integrationData = {
      stepData: { name, description },
      ...integrationData,
    };

    const ases = {
      ...data,
      name,
      description,
      integrationData,
      ...ext,
    };

    editStepinTrigger(ases);
  }

  function renderItem({ name: left, integrationData }) {
    const { icon, name } = integrationData;

    const right = icon;

    return BearFloat({
      left,
      right,
    });
  }

  const title = <Input />;

  const list = testChooseStepOptions;
  const subtitle = BearList({
    list,
    click,
    // spaceBetween: "10vh",
    renderItem,
    itemConfig: {
      className: "shadowMain buttonHover",
    },
  });

  const onBase = BearTitle({
    title,
    subtitle,
    fontSize: "20px",
    style: { padding: "0 30px" },
  });

  const center = "Action";

  const backType =
    //
    "";
  // integrationData ? "chooseType" : "chooseFunc";

  return TriggerStepChooseContainer(onBase, center, {
    backType,
    right,
  });
}
