import React from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearColors,
  BearFloat,
  BearBalance,
  BearLine,
  BearDivMain,
} from "@sillyscribe95/bear-ui-web";
import { useState } from "react";
import { testInput } from "./input-user-data";
import { DashboardPage } from "../dashboard-page";
import { Input, Popover } from "antd";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { InputOptionTypes } from "./input-option-types";
import { mobView } from "../../const/main-const";
import { random } from "lodash";
import { AddButton } from "../../components/sidebar/step-add-button";
import { OptionsButton } from "../../components/sidebar/options-button";
import { getInputTypes } from "./const/input-const";
import { findAllAndReplace } from "../../functions/main-funcs";

export function InputEditMain(data) {
  const { TextArea } = Input;
  const { fields } = data;
  const [inputList, setinputList] = useState(fields);
  // const [options, setoptions] = useState();
  // const [inputType, setinputType] = useState();

  // 1add
  function addListo() {
    const newBase = {
      id: random(7),
      key: "",
      placeholder: "Enter name of field",
      // inputType: "paragraph",
    };

    setinputList([
      ...inputList,
      //
      newBase,
    ]);
  }

  function renderItem(base, input) {
    bearlog.lug(`___ base ___`, base);

    const { id, key, inputType = getInputTypes("text"), placeholder } = base;

    // 1changeType
    function click(inputType) {
      const newItem = {
        ...base,
        inputType,
      };
      const newList = findAllAndReplace(inputList, newItem, id);

      bearlog.lug(`___ newList ___`, newList);
      setinputList(newList);
    }

    const eqBas = input + 1 !== inputList.length;
    const { text: NameTypeInput, icon } = inputType;

    const insa = {
      size: "large",
      defaultValue: key,
      placeholder,
    };

    let inpType = <Input {...insa} />;

    const left = inpType;
    const content = InputOptionTypes({
      spaceBetween: "20px",
      click,
      fontSize: "18px",
    });

    const sdawew = BearBalance(icon, NameTypeInput);

    const popFig = {
      content,
      placement: "bottom",
      children: BearDivMain(sdawew, {
        fontSize: "0.7em",
        style: { minWidth: "130px", padding: "5px 10px" },
        className: "shadowMain buttonHover",
      }),
    };

    // return "qsd";

    const center = (
      //
      //   content;
      <Popover {...popFig} />
    );

    const right = OptionsButton({
      // onDelete
    });

    const naksdfe = BearFloat({
      //
      left,
      center,
      right,
    });

    return (
      <>
        {naksdfe}
        {/* {eqBas && BearLine("5vh")} */}
      </>
    );
  }

  const baseo = BearList({
    list: inputList,
    renderItem,
    spaceBetween: "10vh",
  });

  const addios = AddButton({
    onClick: addListo,
  });

  return (
    <>
      {baseo}
      {addios}
    </>
  );
}
