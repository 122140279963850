import { bearlog } from "@sillyscribe95/bear-ui-web";
import { remove } from "lodash";

export function triggerStepRemoveFunc(data, trigg) {
  const { path: oldpath } = trigg;
  const path = remove(oldpath, function ({ id }) {
    return id !== data.id;
  });

  bearlog.lug(`___ triggeepRemoveFunc ___`, { oldpath, path });

  return {
    ...trigg,
    path,
  };
}
