import React from "react";
import {
  bearlog,
  BearDiv,
  BearBalance,
  BearList,
} from "@sillyscribe95/bear-ui-web";

export function IntegrationIconListComponent(args) {
  function renderItem({ icon }) {
    return icon;
  }

  return BearList({
    horiz: true,
    renderItem,
    spaceBetween: "5px",
    ...args,
  });
}
