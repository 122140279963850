import "./App.css";
import "antd/dist/antd.css";
import { BearContextProvider } from "@sillyscribe95/bear-ui-web";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { HomePage } from "./main/pages/HomePage";
import { IntegrationPage } from "./main/containers/integrations/integration-page";
import { TestPage } from "./main/test/test-page";
import { QueryClient, QueryClientProvider } from "react-query";
import { ClosePage } from "./main/containers/close/close-page";
import { TriggersNewContainer } from "./main/containers/triggers/triggers-new-container";
import { TriggersMain } from "./main/containers/triggers/triggers-main";
import { TriggerPage } from "./main/containers/triggers/trigger-page";
import MainContextProvider from "./main/context/main-context";
import TriggersContextProvider from "./main/containers/triggers/context/triggers-context";
import { SettingsPage } from "./main/containers/settings/settings-page";
import { InputPage } from "./main/containers/inputs/input-page";
import { ExplorePage } from "./main/containers/explore/explore-page";
import { EnginePage } from "./main/containers/engine/engine-page";
import { CompsPage } from "./main/containers/comps/comps-page";
// import { ChakraProvider } from "@chakra-ui/react";

function App() {
  function compBase(type) {
    return `/${type}/:id?/:option?`;
  }
  const queryClient = new QueryClient();

  return (
    // <QueryClientProvider client={queryClient}>
    <MainContextProvider>
      <TriggersContextProvider>
        <BrowserRouter>
          <Switch>
            {/* <Route path="/contact/:type?" component={FormPage} /> */}
            {/* <Route path="complete" component /> */}
            <Route path={compBase("inputs")} component={InputPage} />
            <Route path={compBase("triggers")} component={TriggerPage} />
            <Route
              path={compBase("integrations")}
              component={IntegrationPage}
            />
            <Route path="/engine/:type/" component={EnginePage} />
            {/* <Route path="/auth" component={AuthPage} /> */}
            <Route path="/explore" component={ExplorePage} />
            <Route path="/settings" component={SettingsPage} />
            <Route path="/test" component={TestPage} />
            <Route path="/comps/:id?" component={CompsPage} />
            <Route path="/close" component={ClosePage} />
            <Route path="/new" xxcxomponent={TriggersNewContainer} />
            <Route
              path="/"
              component={
                //
                // IntegrationPage
                // TestPage
                TriggerPage
              }
            />
          </Switch>
        </BrowserRouter>
      </TriggersContextProvider>
    </MainContextProvider>
    //  </QueryClientProvider>
  );
}

export default App;
