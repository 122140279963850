import { SiGmail } from "react-icons/si";

const gmail = {
  name: "Gmail",
  icon: SiGmail({
    color: "red",
  }),
  integrationSlug: "gmail",
  integrationType: "email",
};

export const optionsEmailData = {
  gmail,
};
