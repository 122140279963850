import { bearlog } from "@sillyscribe95/bear-ui-web";
import { SiStripe, SiGmail, SiGooglesheets } from "react-icons/si";
import { triggerStepExternalOptionsData } from "../../triggers/trigger-step-external-options-data";
import { optionsCalendarData } from "./integrations-options-calendar-data";
import { optionsEmailData } from "./integrations-options-email-data";
import { optionPaymentsData } from "./integrations-options-payment-data";
import { optionSheetsData } from "./integrations-options-sheets-data";
import { optionsFilesData } from "./integrations-options-files-data";
import { optionsFormsData } from "./integrations-options-forms-data";

import { userIntegrations } from "./integrations-user-data";

const email = {
  gmail: optionsEmailData["gmail"],
  //  Outlook:
};

const sheets = {
  gsheets: {
    name: "Sheets ",
    icon: SiGooglesheets(),
    apiPoint: "http://www.google.com",
  },
  //  Outlook:
};

export function integrationOptionData(type) {
  const nsdsa = {
    //
    email,
    calendar: optionsCalendarData,
    payments: optionPaymentsData,
    sheets: optionSheetsData,
    forms: optionsFormsData,
    files: optionsFilesData,
  };
  return type ? nsdsa[type] : nsdsa;
}

export function getUserIntegrations() {
  const { integrationData } = userIntegrations;
  const ajsdnw = integrationOptionData();

  const nosasd = Object.entries(integrationData).map(function ([
    itemName,
    currUserOption,
  ]) {
    // const currUserOption = integrationData[itemName];
    const mainIntDeerts = integrationOptionData(itemName)[currUserOption];
    const stepList = triggerStepExternalOptionsData[currUserOption];

    return {
      ...mainIntDeerts,
      stepList,
    };
  });

  bearlog.lug(`___ getUseregrations ___`, nosasd);

  return nosasd;
}

export function getAllIntegrations() {
  return [
    ...Object.values(optionsCalendarData),
    ...Object.values(optionPaymentsData),
    ...Object.values(optionSheetsData),
  ];
}
