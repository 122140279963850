import React from "react";
import {
  bearlog,
  BearDiv,
  BearBalance,
  BearDivMain,
  BearColors,
  capitalizeFirstLetter,
} from "@sillyscribe95/bear-ui-web";
import { DashboardPage } from "../dashboard-page";
import { BsFillPersonFill } from "react-icons/bs";
import { testInput } from "./input-user-data";
import { useHistory } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";

export function InputContainerMain(main, type) {
  const inputData = testInput;
  const { name, numberOfResponses, id } = inputData;

  const history = useHistory();
  let right = BearBalance(BsFillPersonFill(), numberOfResponses);

  function rendBase(maino) {
    const inluds = maino === type;
    const base = inluds && BearColors("white", "darkblue");

    bearlog.lug(`___ inluds ___`, { maino, type, inluds, base });

    function onClick() {
      const basj = `/inputs/${id}${inluds ? "" : `/${maino}`}`;
      bearlog.lug(`___ basj ___`, basj);
      history.push(basj);
    }

    return {
      ...base,
      className: "buttonHover shadowMain",
      onClick,
    };
  }

  right = BearDivMain(right, {
    ...rendBase("responses"),
  });

  const sdkf = BearBalance(FiEdit2(), "Edit");

  const left = BearDivMain(sdkf, {
    ...rendBase("edit"),
  });

  const center =
    //
    `${type ? `${capitalizeFirstLetter(type)} - ` : ""}${name}`;

  const titleConfig = {
    center,
    left,
    right,
    fontSize: "0.7em",
  };

  return DashboardPage("inputs", main, {
    titleConfig,
    backTrue: true,
  });
}
