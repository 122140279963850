import React from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearBalance,
  BearFloat,
  BearDivMain,
} from "@sillyscribe95/bear-ui-web";
import { AiOutlineMail } from "react-icons/ai";
import {
  emailTypes,
  integrationsTypes,
  integrationOptions,
} from "./data/integrations-types-data";
import { IntegrationsOptionsButton } from "./integrations-options-button";
import { useHistory } from "react-router-dom";
import { DashboardTypeMain } from "../dashboard/dashboard-type-main";
import { integrationOptionData } from "./data/trigger-integration-options-data";
import { userIntegrations } from "./data/integrations-user-data";
import { IntegrationIconListComponent } from "./components/integration-icon-list-component";

export function IntegrationsMain() {
  const { integrationList, integrationData } = userIntegrations;

  const list =
    //
    integrationList;
  // Object.keys(integrationsTypes);

  // const list = getUserIntegrations();

  bearlog.lug(`___ list ___`, list);

  function renderItem(data) {
    // const { itemName } = data;
    const itemName = data;

    const typeData = integrationsTypes[itemName];
    const currUserOption = integrationData[itemName];
    let mainIntDeerts = integrationOptionData(itemName)[currUserOption];
    const { icon, type: name } =
      //
      typeData;

    bearlog.lug(`___ InteationsMain  rendItem___`, {
      data,
      mainIntDeerts,
      typeData,
    });

    const baselo =
      //
      icon;

    // BearBalance(icon, name)

    const intIcon = BearDivMain(baselo, {
      // style: { width: "30vw" },
      // align: "left",
      // fontSize: "0.7em",
      className: "wrap",
    });

    let left = BearBalance(icon, name, {
      align: "left",
      // style: { width: "200px" },
    });

    const center =
      //
      "";
    // intIcon;

    const right =
      //
      IntegrationIconListComponent({
        list: [mainIntDeerts],
      });
    // BearBalance(intIcon, IntegrationsOptionsButton(data));

    return BearFloat({
      left,
      center,
      right,
    });
  }

  const itemConfig = {
    // background: "blue",
    // className: "shadowMain",
  };

  const spaceBetween = "20px";

  const okfdsd = BearList({
    list,
    renderItem,
    itemConfig,
    spaceBetween,
  });

  // 1add

  return DashboardTypeMain("integrations", okfdsd);
}
