import React from "react";
import { bearlog, BearDiv } from "@sillyscribe95/bear-ui-web";
import { IntegrationsMain } from "./integrations-main";
import { PageContainer } from "../PageContainer";
import { DashboardPage } from "../dashboard-page";
import { IntegrationsAddNew } from "./integrations-add-new";
import { DashboardTypeMain } from "../dashboard/dashboard-type-main";
import { IntegrationNewOptions } from "./integration-new-options";

export function IntegrationPage(aaaaa) {
  const {
    //
    match: { params },
    ...args
  } = aaaaa;

  const { id, option } = params;

  bearlog.lug(`___ params ___`, params);

  const main =
    //
    // IntegrationsAddNew;
    option ? IntegrationNewOptions : id ? IntegrationsAddNew : IntegrationsMain;
  // const addType = IntegrationNewOptions

  return main({
    id,
    option,
  });
}
