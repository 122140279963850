import {
  BiMoney,
  BiEnvelope,
  BiSpreadsheet,
  BiFolderOpen,
  BiCalendar,
} from "react-icons/bi";
import { FaWpforms } from "react-icons/fa";

const email = {
  type: "Email",
  icon: BiEnvelope(),
  description: "Send and Receive email from your prefered account",
  optionList: ["Gmail"],
};

const files = {
  type: "Files",
  icon: BiFolderOpen(),
  description: "Upload or Move file from your CRM",
  optionList: ["gdrive", "dropbox", "onedrive"],
};

const payments = {
  type: "Payments",
  icon: BiMoney(),
  description: "Triggers when a payment is received.",
};

const calendar = {
  type: "Calendar",
  icon: BiCalendar(),
  description: "Book and receive updates about meetings.",
};

const sheets = {
  type: "Sheets",
  icon: BiSpreadsheet(),
  description: "Triggers when a new spreadsheet is created.",
};

const forms = {
  type: "Forms",
  icon: FaWpforms(),
  description: "Triggers when a new form is uploaded.",
};

export const integrationsTypes = {
  email,
  forms,
  payments,
  sheets,
  calendar,
  files,
};
