import React, { useContext } from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearColors,
  BearBalance,
  BearTitle,
  BearDivMain,
  BearHeight,
} from "@sillyscribe95/bear-ui-web";
import { useState } from "react";
import { TriggersContext } from "./context/triggers-context";
import { TriggerInputExternalOptions } from "./trigger-input-external-options";
import { TriggerInputInternalOptions } from "./trigger-input-internal-options";
import { capitalize } from "lodash";

export function TriggersNewInputType() {
  const { triggerInputType = "int", setTriggerInputType } =
    useContext(TriggersContext);

  const chosenConfig = BearColors("white", "black");

  const itemConfig = {
    align: "center",
    style: {
      //
      alignItems: "center",
      // height: "160px",
      // width: "200px",
      padding: "0 10vw",
    },
    className: "center shadowMain buttonHover",
  };

  function renderItem({ itemName, description, icon }) {
    const title = BearBalance(icon, itemName);
    const subtitle = description;

    const knadre = BearTitle({
      title,
      subtitle,
      style: {
        marginTop: "30px",
      },
    });

    return knadre;
  }

  const dictvar = {
    int: {
      name: "Internal",
      // icon:
      description: "Something your or your team fills out.",
    },
    ext: {
      name: "External",
      image: "https://i.ibb.co/qgmQhdg/another.png",
      description: "Choose an endpoint from one of our APIs.",
    },
  };

  // 1click
  function click() {
    // settriggerData({
    //   integrationData
    // })
  }

  // const title = "Choose Input";
  const listTop = BearList({
    list: ["int", "ext"],
    dictvar,
    itemConfig,
    chosenItem: triggerInputType,
    chosenConfig,
    click: ({ itemName }) => setTriggerInputType(itemName),
    // renderItem,
    renderItem: ({ name }) => capitalize(name),
    // spaceBetween: "5vh",
    fontSize: "24px",
    horiz: true,
  });

  let resso = {
    int: TriggerInputInternalOptions,
    ext: TriggerInputExternalOptions,
  }[triggerInputType];

  // function click()

  const res = (
    <>
      {/*  */}
      {listTop}
      {BearHeight("5vh")}
      {/* <hr /> */}
      {resso && resso({})}
    </>
  );

  return res;
}
