import React, { useContext, useState } from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearBalance,
  BearDivMain,
} from "@sillyscribe95/bear-ui-web";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { GrDuplicate } from "react-icons/gr";
import { GiSightDisabled } from "react-icons/gi";
import { Popover } from "antd";

export function OptionsButton({ onDelete, onDuplicate, onDisable }) {
  const deleteOpt = {
    color: "red",
    icon: AiFillDelete(),
    text: "Delete",
    onClick: onDelete,
  };

  const disableOpt = {
    color: "darkblue",
    icon: GrDuplicate(),
    text: "Disable",
    onClick: onDisable,
  };

  const duplicateOpt = {
    // color: "black/",
    icon: GiSightDisabled(),
    text: "Duplicate",
    onClick: onDuplicate,
  };

  const dictvar = {
    deleteOpt,
    disableOpt,
    duplicateOpt,
  };

  const list = [
    //
    "disableOpt",
    "duplicateOpt",
    "deleteOpt",
  ];

  function renderItem({ text, icon, className, ...asd }) {
    const res =
      //
      BearBalance(icon, text, asd);
    //   text;

    return res;
  }

  const itemConfig = {
    className: "shadowMain buttonHover",
    fontSize: "20px",
    align: "left",
  };

  const style = {
    // border: "1px solid black",
    padding: "20px",
  };

  const content = BearList({
    list,
    style,
    itemConfig,
    spaceBetween: "3vh",
    dictvar,
    renderItem,
  });

  const children =
    //
    BearDivMain(BsThreeDotsVertical(), {
      className: "pointer",
    });

  const popConfig = {
    content,
    children,
    // trigger: "click",
    placement: "bottom",
  };

  const res = <Popover {...popConfig} />;

  return res;
}
