import React from "react";
import {
  bearlog,
  BearDiv,
  BearTitle,
  BearFloat,
  BearDivMain,
  BearList,
  BearColors,
  BearBorder,
  BearBalance,
} from "@sillyscribe95/bear-ui-web";
import { testInput } from "./input-user-data";
import { DashboardPage } from "../dashboard-page";
import { InputContainerMain } from "./input-container-main";
import { linkExtIcon } from "../../const/main-const";

export function InputResponsesContainer({ id }) {
  const { respsonseDatabase, responseData: list } = testInput;

  bearlog.lug(`___ InputRespoContainer ___`, testInput);

  function renderItem({ email, name, createdDate }) {
    const right = BearDivMain(createdDate, {
      fontSize: "0.6em",
      ...BearColors("white", "green"),
      style: { ...BearBorder("green", "5px") },
      // style: { padding: "5px" },
    });
    const title = BearFloat({
      left: name,
      right,
    });

    return BearTitle({
      title,
      subtitle: email,
    });
  }

  const spaceBetween = "2vh";

  const itemConfig = {
    style: { padding: "10px" },
    className: "shadowMain buttonHover",
  };

  const subtitle = BearList({
    list,
    spaceBetween,
    lineBetween: true,
    itemConfig,
    renderItem,
  });

  function Datago() {
    const { sheetInfo, integrationData } = respsonseDatabase;
    const { icon, text } = integrationData;
    const { name, link } = sheetInfo;

    const amsdw = `Sheet: "${name}"`;

    const left = BearBalance(icon, amsdw, {
      className: "wrap",
    });

    const right = linkExtIcon;

    return BearFloat({
      left,
      right,
      className: "shadowMain buttonHover",
      ...BearColors("black"),
      outsideLink: link,
    });
  }

  const title = respsonseDatabase && Datago();

  const nejdsao = BearTitle({
    //
    title,
    subtitle,
    spaceBetween: "25px",
  });

  return InputContainerMain(nejdsao, "responses");
}
