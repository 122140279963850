import React from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearDivMain,
} from "@sillyscribe95/bear-ui-web";
import { Input } from "antd";
import { BearFormBasic } from "../../../bear/form/bear-form-basic";

export function InputForm(data) {
  const { fields: list, name } = data;

  function renderItem({ key, value }) {
    // const { text: NameTypeInput, icon } = inputType;

    const autocomplete =
      //
      "";
    //   key;
    // "username";
    // "nickname";
    // "new-password"

    const id = key;

    const inpConfig = {
      autocomplete,
      placeholder: value,
    };

    let inpType = <Input {...inpConfig} />;
    // switch (NameTypeInput) {
    //   case "Paragraph":
    //     inpType = <TextArea {...insa} />;
    //     break;
    //   default:
    //     inpType = <Input {...insa} />;
    // }

    return BearList({
      style: { textAlign: "left" },
      list: [key, inpType],
    });
  }

  function onSubmit(ASMioq) {
    bearlog.lug(`___ ASMioq ___`, ASMioq);
  }

  const buttonConfig = {
    style: { textAlign: "center" },
  };

  const bearConfo = {
    onSubmit,
    buttonConfig,
  };

  return BearDivMain(
    <>
      <BearFormBasic {...bearConfo}>
        {BearList({
          list,
          renderItem,
          spaceBetween: "5vh",
        })}
      </BearFormBasic>
    </>,
    {
      align: "center",
    }
  );
}
