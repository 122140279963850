import { SiGoogledrive, SiDropbox } from "react-icons/si";

const gdrive = {
  name: "Google Drive",
  icon: SiGoogledrive({
    color: "yellow",
  }),
  numTriggers: 2,
  numActions: 10,
  integrationSlug: "google-drive",
  integrationType: "payment",
  // inputList: stripeInputs,
  // redirect:
};

const dropbox = {
  name: "Dropbox",
  icon: SiDropbox({
    color: "blue",
  }),
  numTriggers: 2,
  numActions: 10,
};

export const optionsFilesData = {
  gdrive,
  dropbox,
};
