import { bearlog } from "@sillyscribe95/bear-ui-web";
import { indexOf } from "lodash";

export function getStepIndex(id, triggerData) {
  const { path } = triggerData;
  const mapIds = path.map(({ id }) => id);
  let currVal =
    //
    // indexOf(path, data);
    indexOf(mapIds, id);

  bearlog.lug(`___ getStIndex aaa ___`, { id, mapIds, currVal });

  return currVal;
}
