import React from "react";
import { bearlog, BearDiv } from "@sillyscribe95/bear-ui-web";
import { useEffect } from "react";

export function ClosePage() {
  useEffect(() => {
    window.close();

    //   return () => {
    //     second
    //   }
  }, []);

  const okfdsd = <></>;

  return okfdsd;
}
