import { bearlog } from "@sillyscribe95/bear-ui-web";
import { getStepIndex } from "./trigger-funcs-main";

export function triggerStepEditFunc(
  newItem,
  triggerData,
  { id, addIndex = 0, spliceAmount = 1 } = ""
) {
  const currID = id ? id : newItem.id;
  const { path } = triggerData;
  const postition = getStepIndex(currID, triggerData);
  const addBase = postition + addIndex;

  bearlog.lug(`___ triggerStditFunc AAAA ___`, {
    currID,
    postition,
    addBase,
    spliceAmount,
    newItem,
  });

  path.splice(addBase, spliceAmount, newItem);

  const newData = {
    ...triggerData,
    path,
  };

  bearlog.lug(`___ triggerSpEditFunc bbbb ___`, {
    currID,
    path,
    newData,
  });

  return newData;
}
