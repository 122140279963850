import { SiGmail } from "react-icons/si";
import { newClientInput, newVendorInput } from "../inputs/input-user-data";
import { optionsEmailData } from "../integrations/data/integrations-options-email-data";
import { optionSheetsData } from "../integrations/data/integrations-options-sheets-data";
import { optionPaymentsData } from "../integrations/data/integrations-options-payment-data";

// 1trigger

export const uploadSheets = {
  id: "dvbjfd9",
  name: "add new client",
  integrationData: {
    ...optionSheetsData["gsheets"],
    stepData: {
      name: "Create Spreadsheet Row",
      // id: "",
    },
    currentAccount: "test@email.com",
    accounts: [
      //
      "test@email.com",
      "info@email.com",
    ],
  },
  fields: [
    //
    { type: "email", name: "Email", defaultValue: "andrew@gmail.com" },
    { type: "name", name: "Name", defaultValue: "Andrew Maguire" },
  ],
};

export const sendEmail = {
  id: "asdfokwerw",
  name: "new client intro",
  integrationData: {
    ...optionsEmailData["gmail"],
    stepData: {
      name: "Send Email",
      id: "send-email",
    },
    currentAccount: "test@gmail.com",
    accounts: [
      //
      "test@gmail.com",
      "info@gmail.com",
    ],
  },
  fields: [
    //
    { type: "to", name: "To", defaultValue: "andrew@gmail.com" },
    { type: "from", name: "From", defaultValue: "info@triggi.co" },
    { type: "subject", name: "Subject Line" },
    { type: "message", name: "Message" },
  ],
  replacements: {
    //
  },
};

export const newCustomerStripe = {
  id: "zxcvsd9",
  name: "new customer",
  integrationData: {
    ...optionPaymentsData["stripe"],
    stepData: {
      name: "Create Customer",
      // id: "send-email",
    },
    currentAccount: "test@gmail.com",
    accounts: [
      //
      "test@gmail.com",
      "info@gmail.com",
    ],
  },
  fields: [
    //
    { type: "to", name: "Name", defaultValue: "Andrew Maguire" },
    { type: "from", name: "Email", defaultValue: "andrew@gmail.com" },
    // { type: "from", name: "Address" },
  ],
  replacements: {
    //
  },
};

// 1path 1steps
const path = [
  //
  uploadSheets,
  newCustomerStripe,
  sendEmail,
];

export const newClientTrigger = {
  testResponse: [
    { key: "name", value: "Andrew Maguire" },
    { key: "email", value: "andrewmg.base" },
  ],
  // input: {
  //   name: newClientInput.name,
  //   inputList: newClientInput,
  // },
  input: newClientInput,
  path,
  name: "New Client Process",
  id: "aosdk9121",
  uses: 22,
  emoji: "🧍",
  created: "2d",
  description:
    "The process to go through after speaking to a potential client.",
};

export const sdfoidsf = {
  testResponse: [
    { key: "name", value: "Andrew Maguire" },
    { key: "email", value: "andrewmg.base" },
  ],
  // input: ,
  path,
  name: "let people in the team know about new client",
  id: "aosdk9121",
  uses: 11,
  emoji: "🧍",
  created: "2d",
  description:
    "The process to go through when adding a new client to our database.",
};

export const newVendorTrigger = {
  input: newVendorInput,
  path,
  emoji: "💼",
  name: "New Vendor Process",
  id: "aosdk9121",
  uses: 4,
  created: "1w",
  description:
    "The process to go through after speaking to a potential vendor.",
};

// const

export const testStep = sendEmail;
export const testTriggger = newClientTrigger;
export const userTriggers = [
  //
  newClientTrigger,
  newVendorTrigger,
];
