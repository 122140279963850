import React from "react";
import {
  bearlog,
  BearDiv,
  BearBalance,
  BearFloat,
} from "@sillyscribe95/bear-ui-web";
import { backColor, newStepIcon } from "../../../const/main-const";
import { TriggerStepOptionButton } from "../../triggers/trigger-step-option-button";

export function TriggerStepPanelComponent(
  data,
  { optionButton = TriggerStepOptionButton, ...txt } = ""
) {
  // // <Input {...inpConfig} />;

  const { name = "New Step", integrationData } = data;

  const inpConfig = { defaultValue: name };

  const titles = name;
  const ico = integrationData ? integrationData.icon : newStepIcon;
  const left = BearBalance(ico, titles);
  const right =
    //
    optionButton(data);

  return BearFloat({
    // vertAlign: false,
    left,
    right,
    className: "buttonHover shadowMain",
    style: {
      // border: "1px solid black",
      background: backColor,
      padding: "10px",
    },
    ...txt,
    // onClick,
  });
}
