import React, { useContext, useState } from "react";
import {
  bearlog,
  BearDiv,
  BearFloat,
  BearDivMain,
  BearList,
  BearBalance,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import { Divider, Input, Popover } from "antd";
import { TriggersContext } from "./context/triggers-context";
import { ItemCase } from "../../components/sidebar/item-case";
import { TriggerPathEdit } from "./trigger-path-edit";
import { indexOf, random } from "lodash";
import { TriggerStepContainer } from "./trigger-step-container";
import { FaQuestion } from "react-icons/fa";
import { newStepType } from "../../const/main-const";
import { TriggerStepPanelComponent } from "../integrations/components/trigger-step-panel-component";
import { AddButton } from "../../components/sidebar/step-add-button";

export function TriggerPathEntry(data, renderContainer) {
  const { triggerData, settriggerData, setstepViewType } = useContext(
    TriggersContext
  );
  const { path } = triggerData;
  bearlog.lug(`___ TriggerPEntry ___`, { data, path });

  const { index, type, id, name, fields, integrationData = "" } = data;

  const nextData = path[index + 1];
  const nextType = nextData?.type === newStepType;
  const newTrue = type == newStepType;
  const { currFocus, setcurrFocus, addStepToTrigger } = useContext(
    TriggersContext
  );
  const editTrue = currFocus == id;

  bearlog.lug(`___ TrgerPathEntry ___`, { newTrue, data });

  // 1focus 1click
  function click() {
    const { id } = data;
    bearlog.lug(`___ currView ___`, currFocus);

    // if (currFocus) {
    setstepViewType("main");
    setcurrFocus(id);
    // }
  }

  //   1add 1plus 1new
  function addbutaso() {
    function onClick() {
      if (!newTrue) {
        addStepToTrigger(data, {
          type: newStepType,
          name: "New Step",
        });
      }
    }

    const className =
      //
      // "shadowMain buttonHover",
      // "disabled shadowMain ";
      newTrue ? "disabled shadowMain" : "shadowMain buttonHover";

    return AddButton({
      onClick,
      className,
    });
  }

  let baseIte = editTrue
    ? TriggerPathEdit(data, renderContainer)
    : TriggerStepPanelComponent(data);

  baseIte = BearDivMain(baseIte, {
    onClick: click,
  });

  const iemasm = (
    <>
      {baseIte}
      {addbutaso()}
    </>
  );

  return iemasm;
}
