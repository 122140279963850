import React from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearDivMain,
  BearFloat,
  BearTitle,
  BearColors,
} from "@sillyscribe95/bear-ui-web";
import { testUserData } from "../../const/test-user-data";
import { BsThreeDotsVertical } from "react-icons/bs";

export function SettingsUsers() {
  const { userList: list } = testUserData;

  function renderItem(email) {
    const left = email;
    const right = BsThreeDotsVertical();

    const end = BearFloat({
      left,
      right,
    });

    return end;
  }

  const itemConfig = {
    className: "shadowMain buttonHover",
  };

  function addUser() {}

  const left = "Users";

  const right = BearFloat({
    center: BearDivMain("+ Add Team Member ", {
      onClick: addUser,
      fontSize: "30px",
      ...BearColors("white", "darkblue"),
      style: { padding: "0 10px" },
      className: "shadowMain buttonHover",
    }),
  });

  const subtitle = BearList({
    renderItem,
    spaceBetween: "5vh",
    itemConfig,
    list,
  });

  return (
    <>
      {subtitle}
      {/* {Bear} */}
      {right}
    </>
  );
}
