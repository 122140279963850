import {
  BearBalance,
  BearColors,
  BearDivMain,
  BearFloat,
  bearlog,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import { Popover } from "antd";
import { mobView, testAppTrue, userIcon } from "../../const/main-const";
import { TriggerStepFields } from "./trigger-step-fields";

export function TriggerStepMiddle(data, setstepViewType) {
  const { integrationData = "" } = data;
  const {
    icon: intIcon,
    stepData,
    name: intName,
    currentAccount,
  } = integrationData;

  bearlog.lug(`___ TriggerStepMiddle ___`, { data, integrationData });

  const { name } = stepData;

  function onClick() {
    //

    bearlog.lug(`___ change int function ___`);

    setstepViewType("chooseFunc");
  }

  function rendType() {
    const nsdiasd =
      //
      name;
    // BearBalance(functionIcon, name)

    return BearDivMain(nsdiasd, {
      //
      onClick,
      style: { maxWidth: "180px", padding: "0 10px" },
      className: "shadowMain buttonHover wrap",
    });
  }

  // 1accounts

  const nkasdwe =
    //
    // currentAccount
    "test@email.com";

  let accoutBase = BearDivMain(nkasdwe, {
    className: "wrap",
    style: { width: "40px" },
  });

  const icnGo =
    //
    userIcon;
  // intIcon

  accoutBase = BearBalance(icnGo, accoutBase, {
    className: "shadowMain buttonHover wrap",
    fontSize: "0.85em",
  });

  // 1test
  function rendTest() {
    function testGo() {
      bearlog.lug(`___ onClick ___`);
    }

    const disabled = "disabled";

    const children = BearDivMain("Test", {
      ...BearColors("white", "darkblue"),
      onClick: !disabled && testGo,
      style: { padding: "0 10px" },
      className: `shadowMain buttonHover ${disabled}`,
    });

    const content = BearDivMain(
      `Integrate your ${intName} account to run tests.`,
      !mobView && {
        style: { width: "150px" },
      }
    );

    const popFig = {
      content,
      placement: "bottom",
      children,
    };

    return <Popover {...popFig} />;
  }

  const compLink = BearDivMain("^", {
    link: "/comps/edit-trigger-step-sheets",
  });

  const left = rendType();
  const center = accoutBase;
  const right =
    //
    testAppTrue ? BearBalance(compLink, rendTest()) : rendTest();

  const title = BearFloat({
    left,
    center,
    right,
  });

  const subtitle = TriggerStepFields(data);

  return BearTitle({
    fontSize: "0.7em",
    title,
    subtitle,
    lineBetween: true,
    spaceBetween: "20px",
  });
}
