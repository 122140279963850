import React, { useContext } from "react";
import {
  bearlog,
  BearDiv,
  BearTitle,
  BearList,
  BearFloat,
  BearBalance,
  BearDivMain,
} from "@sillyscribe95/bear-ui-web";
import { getAllIntegrations } from "../integrations/data/trigger-integration-options-data";
import { IntegrationComponent } from "../integrations/integration-component";
import { TriggersContext } from "./context/triggers-context";
import { backIcon } from "../../const/main-const";
import { TriggerStepChooseContainer } from "./trigger-step-choose-container";

export function TriggerStepChooseIntegration(data, stepViewType) {
  const { triggerData } = useContext(TriggersContext);
  //   const [, set] = useState();

  const { integrationData } = data;

  let list = getAllIntegrations();

  bearlog.lug(`___ list ___`, list);

  function click(integrationData) {
    //

    const newStep = {
      ...data,
      integrationData,
    };

    bearlog.lug(`___ triggerData ___`, {
      integrationData,
      newStep,
      data,
      triggerData,
    });

    // return
  }

  const itemConfig = {
    className: "shadowMain buttonHover",
    style: {
      width: "25vw",
      textAlign: "left",
    },
  };

  const subtitle = BearList({
    list,
    itemConfig,
    renderItem: ({ icon, name }) => BearBalance(icon, name),
    displayNumber: 2,
    spaceBetween: "10px",
    click,
  });

  const center = "Integrations";

  const backType =
    //
    // "";
    integrationData ? "chooseType" : "chooseFunc";
  // integrationData ? "chooseFunc" : "main";
  // integrationData ? "chooseFunc" : "chooseType";
  // "chooseFunc";

  return TriggerStepChooseContainer(subtitle, center, {
    backType,
    subtitle,
  });
}
