import React, { useContext, useState } from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearBalance,
} from "@sillyscribe95/bear-ui-web";
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { GrDuplicate } from "react-icons/gr";
import { GiSightDisabled } from "react-icons/gi";
import { Popover } from "antd";
import { TriggersContext } from "./context/triggers-context";
import { OptionsButton } from "../../components/sidebar/options-button";

export function TriggerStepOptionButton(data) {
  bearlog.lug(`___ TriggerStepOptionButtob ___`, data);
  const { currFocus, setcurrFocus, addStepToTrigger, removeStepFromTrigger } =
    useContext(TriggersContext);

  // 1delete
  function onDelete() {
    bearlog.lug(`___ STEP deleteFunc ___`, data);
    removeStepFromTrigger(data);
    //
  }

  function onDuplicate() {
    bearlog.lug(`___ duplicateFunc ___`, data); //
    addStepToTrigger(data, data);
  }

  function onDisable() {
    bearlog.lug(`___ disableFunc ___`, data);
    //
  }

  const res = OptionsButton({
    onDelete,
    onDisable,
    onDuplicate,
  });

  return res;
}
