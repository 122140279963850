import React from "react";
import {
  bearlog,
  BearDiv,
  BearBalance,
  BearDivMain,
} from "@sillyscribe95/bear-ui-web";
import { DashboardPage } from "../dashboard-page";
import { newClientInput, testInput } from "./input-user-data";
import { BsFillPersonFill } from "react-icons/bs";
import { InputContainerMain } from "./input-container-main";
import { InputEditMain } from "./input-edit-main";

export function InputEditContainer({ option }) {
  const inputData = testInput;
  const { name, numberOfResponses } = inputData;
  const okfdsd = InputEditMain(inputData);

  return InputContainerMain(okfdsd, "edit");
}
