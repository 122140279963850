import { SiGmail, SiStripe } from "react-icons/si";
import { optionsEmailData } from "../integrations/data/integrations-options-email-data";
import { optionPaymentsData } from "../integrations/data/integrations-options-payment-data";
import { optionSheetsData } from "../integrations/data/integrations-options-sheets-data";

export const stripe = [
  {
    name: "New Customer",
    description: "Creates a new customer.",
  },
  {
    name: "New Payment Link",
    description: "Creates a payment link.",
  },
  {
    name: "Create Price",
    description: "Creates a price.",
  },
  {
    name: "Create Product",
    description: "Creates a new product.",
  },
  {
    name: "Deactivate Payment Link",
    description: "Deactivates a payment link.",
  },
  {
    name: "Update Customer",
    description: "Updates an existing customer.",
  },
  {
    name: "Find Charge",
    description: "Finds an existing charge by its Stripe ID.",
  },
];

export const gmail = [
  {
    name: "Send Email",
    description: "Triggers when a new e-mail appears in the specified mailbox.",
  },
  { name: "Create Label", description: "Triggers when you add a new label." },
  {
    name: "New Attachment",
    description:
      "Triggers when you receive a new attachment (triggers once per attachment).",
  },
];

const gsheets = [
  {
    name: "New Column",
    description: "Create a new column in a specific spreadsheet.",
  },
  {
    name: "New Row",
    description: "Create a new row in a specific spreadsheet.",
  },
  {
    name: "Delete Spreadsheet Row",
    description:
      "Deletes the content of a row in a specific spreadsheet. Deleted rows will appear as blank rows in your spreadsheet. Please use with caution.",
  },
  {
    name: "Update Spreadsheet Row",
    description: "Update a row in a specific spreadsheet.",
  },
];

export const triggerStepExternalOptionsData = {
  gsheets,
  gmail,
  stripe,
};

export const testChooseStepOptions = [
  {
    name: "New Row",
    description: "Create a new row in a specific spreadsheet.",
    integrationData: optionSheetsData["gsheets"],
    fields: [{ name: "Name" }, { name: "Email" }],
  },
  {
    integrationData: optionsEmailData["gmail"],
    name: "Create Label",
    description: "Triggers when you add a new label.",
    fields: [{ name: "Name" }],
  },
  {
    integrationData: optionPaymentsData["stripe"],
    name: "New Payment Link",
    description: "Creates a payment link.",
    fields: [
      //
      { name: "Line items", disableDataInsert: true },
      { name: "Collect Customers' Addresses?", disableDataInsert: true },
    ],
  },
];
