import React, { useState } from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearTitle,
  BearDivMain,
  BearColors,
  BearFloat,
  capitalizeFirstLetter,
} from "@sillyscribe95/bear-ui-web";
import { DashboardPage } from "../dashboard-page";
import { capitalize } from "lodash";
import { openIntegrationPopup } from "../integrations/functions/open-integration-popup";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { DashboardTypeMain } from "../dashboard/dashboard-type-main";
import { BsThreeDotsVertical } from "react-icons/bs";
import { SettingsUsers } from "./settings-users";
import { SettingsBilling } from "./settings-billing";

export function SettingsPage(aaaaa) {
  const {
    //
    match: { params },
    ...args
  } = aaaaa;

  bearlog.lug(`___ params ___`, params);

  // 1billing

  function emailRend() {
    return "test@gmail.com";
  }

  function authRend() {
    return "asdfok";
  }

  const authfactor = {
    renderBase: authRend,
    itemName: "Authentication",
  };

  const email = {
    renderBase: emailRend,
  };

  const users = {
    renderItem: SettingsUsers,
  };

  const billing = {
    renderBase: SettingsBilling,
  };

  const dictvar = {
    email,
    users,
    billing,
  };

  const list = [
    //
    "email",
    "users",
    "billing",
  ];

  const itemConfig = {
    //
  };

  const spaceBetween = "15vh";

  function renderItem({ itemName, renderItem, renderBase }) {
    const rend = renderItem ? renderItem : renderBase;

    const title = capitalizeFirstLetter(itemName);
    const subtitle = BearDivMain(rend(), {
      center: true,
      align: "center",
    });

    return BearTitle({
      title,
      // lineBetween: true,
      // spaceBetween: "20px",

      subtitle,
    });
  }

  const children = BearList({
    spaceBetween,
    itemConfig,
    list,
    dictvar,
    renderItem,
    // dictvar,
  });

  return DashboardPage("settings", children, {
    // addNew,
    // backTrue: true,
    title: "Settings",
  });
}
