import React, { useContext } from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearColors,
  BearFloat,
  BearBalance,
  BearLine,
  BearDivMain,
} from "@sillyscribe95/bear-ui-web";
import { useState } from "react";
import { DashboardPage } from "../dashboard-page";
import { newClientInput, testInput } from "./input-user-data";
import { Input, Popover } from "antd";
import { AiFillEye, AiOutlineEye } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { InputOptionTypes } from "./input-option-types";
import { mobView } from "../../const/main-const";
import { InputEditMain } from "./input-edit-main";

export function InputNewContainer() {
  const history = useHistory();
  function onClick() {
    history.push(`/inputs/new/preview`);
  }

  const dara = testInput;

  const baseoe = InputEditMain(dara);

  const base = mobView
    ? AiOutlineEye()
    : BearBalance(
        //
        // AiOutlineEye(),
        AiFillEye(),
        "Preview"
      );

  const akdmfwe = !mobView && {
    fontSize: "0.7em",
  };

  const titleConfig = {
    right: BearDivMain(base, {
      ...akdmfwe,
      ...BearColors("white", "darkblue "),
      className: "buttonHover",
      style: { padding: " 0 10px" },
      onClick,
    }),
  };

  return DashboardPage("inputs", baseoe, {
    title: "New Input",
    titleConfig,
    backTrue: true,
  });
}
