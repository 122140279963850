import React, { useState } from "react";
import {
  bearlog,
  BearDiv,
  BearFloat,
  BearDivMain,
  BearList,
  BearBalance,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import { Divider, Input, Popover } from "antd";
import { TriggersContext } from "./context/triggers-context";
import { mobView } from "../../const/main-const";
import { TriggerStepOptionButton } from "./trigger-step-option-button";
import { IoMdArrowDropdown } from "react-icons/io";
import { TriggerStepFieldInsertData } from "./trigger-step-field-insert-data";
// import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";

export function TriggerStepFieldsInput(data) {
  const { defaultValue, name, disableDataInsert } = data;

  const jisfew = {
    defaultValue,
    pr: "4.5rem",
  };

  const title = name;

  let right = !disableDataInsert && TriggerStepFieldInsertData(data);
  const rConfo = {
    children: right,
    width: "4.5rem",
  };

  const left = <Input {...jisfew} />;

  //   const putiso = (
  //     <>
  //       {/* <InputGroup size="md"> */}
  //
  //       {/* <InputRightElement {...rConfo} /> */}
  //       {/* </InputGroup> */}
  //     </>
  //   );

  const subtitle = BearFloat({
    left,
    right,
  });

  const nskdas = BearTitle({
    title,
    subtitle,
  });

  return nskdas;
}
