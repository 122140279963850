import { SiGmail, SiStripe } from "react-icons/si";
import { optionsEmailData } from "../integrations/data/integrations-options-email-data";

const paymentInputs = [
  {
    name: "Canceled Subscription",
    description:
      "Triggers when a subscription is canceled (by a subscriber or due to end of billing period).",
  },
  {
    name: "Failed Payment",
    description: "Triggers when a payment attempt fails.",
  },
  {
    name: "New Customer",
    description: "Triggers when a new customer is added.",
  },
  {
    name: "New Subscription",
    description: "Triggers when a customer is signed up for a new plan.",
  },
  {
    name: "New Invoice",
    description:
      "Triggers when a new invoice is created (supports line items).",
  },
  {
    name: "New Payment",
    description: "Triggers when a new payment is completed.",
  },
].map(function (item) {
  return {
    integrationData: {
      icon: SiStripe({
        color: "blue",
      }),
      type: "Stripe",
    },
    ...item,
  };
});

const emailInputs = [
  {
    name: "New Attachment",
    description:
      "Triggers when you receive a new attachment (triggers once per attachment).",
  },
  { name: "New Label", description: "Triggers when you add a new label." },
  {
    name: "New Email",
    description: "Triggers when a new e-mail appears in the specified mailbox.",
  },
].map(function (item) {
  return {
    integrationData: optionsEmailData["gmail"],
    ...item,
  };
});

export const triggerInputExternalOptionsData = [
  //
  ...paymentInputs,
  ...emailInputs,
];
