import React from "react";
import { bearlog, BearDiv, BearList } from "@sillyscribe95/bear-ui-web";
import { ItemCase } from "./item-case";

export function ItemList({ config, ...data }) {
  const itemConfig = {
    style: { padding: "10px" },
    className: "shadowMain buttonHover",
  };

  const style = {
    overflow: "scroll",
    height: "60vh",
  };

  function renderItem(ext) {
    return ItemCase({
      ...config,
      ...ext,
    });
  }

  const okfdsd = BearList({
    itemConfig,
    renderItem,
    spaceBetween: "5vh",
    style,
    ...data,
  });

  return okfdsd;
}
