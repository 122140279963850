import React, { useState } from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearFloat,
  BearDivMain,
  BearBackBorder,
  BearBorder,
  bearMobileView,
  BearColors,
  capitalizeFirstLetter,
  BearBalance,
} from "@sillyscribe95/bear-ui-web";
import { useHistory } from "react-router-dom";
import {
  AiOutlineAccountBook,
  AiOutlineAim,
  AiOutlineMail,
  AiTwotoneWarning,
} from "react-icons/ai";
import { Tooltip } from "antd";
import { capitalize } from "lodash";
import { AiOutlinePlus } from "react-icons/ai";
import { HiPencilAlt } from "react-icons/hi";
import { GrTrigger, GrConnect } from "react-icons/gr";
import { FiGlobe, FiSettings } from "react-icons/fi";
import { FaPlug } from "react-icons/fa";
import { mobView } from "../../const/main-const";

export function SidebarIcons(chosenItem) {
  const history = useHistory();

  const list = [
    "triggers",
    "inputs",
    "integrations",
    "explore",
    "settings",
    // !mobView && ["responses", "settings"],
    // "refer",
  ];

  const trigIcon =
    //
    AiOutlinePlus();
  // GrTrigger();

  const inputIcon =
    //
    HiPencilAlt();

  // 1icons
  const dictvar = {
    triggers: {
      icon: trigIcon,
    },
    inputs: {
      icon: inputIcon,
    },
    integrations: {
      icon:
        //
        // GrConnect(),
        FaPlug(),
      // AiOutlineAccountBook(),
    },
    responses: {
      icon: AiOutlineAim(),
    },
    explore: {
      icon: FiGlobe(),
    },
    settings: {
      icon: FiSettings(),
    },
    // refer: {},
  };

  function click(all) {
    const { itemName } = all;

    bearlog.lug(`___ all ___`, { all, history });

    // setselConst(itemName);
    history.push(`/${itemName}`);
  }

  function renderItem(all) {
    bearlog.lug(`___ all ___`, all);

    const { itemName, icon } = all;
    const endosad =
      //
      mobView
        ? icon
        : BearBalance(icon, capitalize(itemName), {
            align: "left",
          });

    const children = BearDivMain(endosad, {
      // align: "center",
      // style: { width: "20vw" },
      //
    });

    const title =
      //
      // "";
      capitalizeFirstLetter(itemName);

    // <Tooltip
    //   //
    //   placement={mobView ? "top" : "right"}
    //   title={title}
    //   children={children}
    // />

    let adskmr =
      //
      children;
    // title;

    return adskmr;
  }

  const itemConfig = mobView
    ? {
        // background: "red",
        className: "pointer shadowMain",
        // textAlign: "center",
        fontSize: "24px",
        style: {
          width: "20vw",
          paddingTop: "10px",
        },
      }
    : {
        // style: { padding: "10px 20px", borderRadius: "50%" },
        className: "pointer",
      };

  const endpas = !mobView && {
    //
    className: "shadowMain buttonHover",
    // background: " red",
  };
  const chosenConfig = {
    ...endpas,
    // color: "blue",
    // className: "shadowMain",
    ...(mobView && BearColors("white", "darkblue")),
    // ...endpas,
  };

  const spaceBetween = !mobView && "5vh";

  const horiz =
    //
    // "";

    bearMobileView;

  const baseFacing =
    //
    mobView && { bottom: 0 };
  // mobView ? { bottom: 0 } : { left: 20 };

  const style = mobView && {
    zIndex: 1000,
    ...baseFacing,
    background: "white",
    position: "fixed",
    // left: 49.8
  };

  const main = BearList({
    style,
    horiz,
    spaceBetween,
    list,
    dictvar,
    click,
    itemConfig,
    renderItem,
    // spaceBetween,
    chosenItem,
    chosenConfig,
  });

  return main;
}
