import React from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearTitle,
  BearBalance,
  BearDivMain,
  BearFloat,
  capitalizeFirstLetter,
} from "@sillyscribe95/bear-ui-web";
import { integrationsTypes } from "./data/integrations-types-data";
import { openIntegrationPopup } from "./functions/open-integration-popup";
import { integrationOptionData } from "./data/trigger-integration-options-data";
import { DashboardPage } from "../dashboard-page";
import { capitalize } from "lodash";

export function IntegrationNewOptions(data) {
  const { option } = data;
  const typeData = integrationsTypes[option];
  const dictvar = integrationOptionData(option);

  const list = Object.keys(dictvar);
  //   const { optionList, type } = typeData;

  function renderItem(data) {
    const { numActions, numTriggers, name, icon, description } = data;

    const numMessages = `${numTriggers} triggers, ${numActions}
     actions`;

    const left = BearBalance(icon, name, {
      fontSize: "1.3em",
    });
    const right = BearDivMain(
      //
      numMessages,
      {
        //
        fontSize: "0.8em",
      }
    );

    const title = BearFloat({
      //
      left,
      right,
    });
    const subtitle = BearDivMain(description, {
      className: "wrap",
    });

    return BearTitle({
      title,
      subtitle,
    });
  }

  function click(main) {
    bearlog.lug(`___ main ___`, main);

    const { apiPoint, itemName } = main;

    const linko = apiPoint
      ? apiPoint
      : `http://localhost:8888/engine/${itemName}`;

    openIntegrationPopup(linko);
  }

  const itemConfig = {
    className: "buttonHover shadowMain",
    style: { padding: "10px" },
  };

  const lisst = BearList({
    spaceBetween: "20px",
    list,
    dictvar,
    click,
    renderItem,
    itemConfig,
  });

  // const integrationsList =

  const okfdsd = lisst;

  return DashboardPage("integrations", okfdsd, {
    title: `New ${capitalizeFirstLetter(option)} Integration`,
    backTrue: true,
  });
}
