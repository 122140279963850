import React, { useEffect, useRef, useState, createContext } from "react";
import { bearlog } from "@sillyscribe95/bear-ui-web";
import { newClientTrigger, testTriggger } from "../triggers-user-data";
import { indexOf, random } from "lodash";
import { triggerStepEditFunc } from "../functions/trigger-step-edit-func";
import { triggerStepRemoveFunc } from "../functions/trigger-step-remove-func";
export const TriggersContext = createContext();

const TriggersContextProvider = (props) => {
  //   1const
  const [tim, settim] = useState();
  const [currFocus, setcurrFocus] = useState();
  const [triggerData, settriggerData] = useState(
    //
    // ""
    testTriggger
    // newClientTrigger
  );
  const [triggerInputType, setTriggerInputType] = useState();
  const [stepViewType, svasda] = useState(
    //
    "main"
    // "chooseFunc"
    // ""
  );

  function setstepViewType(datao) {
    bearlog.lug(`___ cahngeVIerw ___`, datao);
    svasda(datao);
  }

  // 1test
  const testTrue =
    //
    // "asqewe";
    "";
  //

  const { path } = triggerData;
  const mapIds = path.map(({ id }) => id);

  function editStepinTrigger(data) {
    const newData = triggerStepEditFunc(data, triggerData);
    settriggerData(newData);
  }

  // 1delete
  function removeStepFromTrigger(currStep) {
    const newDat = triggerStepRemoveFunc(currStep, triggerData);
    settriggerData(newDat);
  }

  // 1add
  function addStepToTrigger(prevData, newData) {
    const { id } = prevData;
    const newID = random(7);

    const newStep = {
      ...newData,
      id: newID,
    };
    const newBase = triggerStepEditFunc(newStep, triggerData, {
      id,
      addIndex: 1,
      spliceAmount: 0,
    });

    setcurrFocus(newID);
    settriggerData(newBase);
  }

  function testCheck(bitOne, bitTwo) {
    return testTrue ? bitOne : bitTwo;
  }

  // 1form
  const [viewType, setviewType] = useState();

  // 1console
  const baseScript = {
    base: {
      //   hereo: hereo,
    },
    form: {
      //
    },
  };

  const okwe =
    //
    "base";

  const osdre = okwe ? baseScript[okwe] : baseScript;

  const xvokbcv = {
    tim,
    settim,
    viewType,
    setviewType,
    testCheck,
    triggerData,
    settriggerData,
    currFocus,
    setcurrFocus,
    // 1funcs,
    editStepinTrigger,
    triggerInputType,
    setTriggerInputType,
    // 1functions
    addStepToTrigger,
    stepViewType,
    setstepViewType,
    removeStepFromTrigger,
    // FORM
  };

  let isfas = {
    value: xvokbcv,
  };

  // 1return 1end
  return (
    <TriggersContext.Provider {...isfas}>
      {props.children}
    </TriggersContext.Provider>
  );
};
// }

export default TriggersContextProvider;
