import React, { useState } from "react";
import {
  bearlog,
  BearDiv,
  BearFloat,
  BearDivMain,
  BearList,
  BearBalance,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import { Divider, Input, Popover } from "antd";
import { TriggersContext } from "./context/triggers-context";
import { mobView } from "../../const/main-const";
import { TriggerStepOptionButton } from "./trigger-step-option-button";
import { IoMdArrowDropdown } from "react-icons/io";
import { TriggerStepFieldInsertData } from "./trigger-step-field-insert-data";
import { TriggerStepFieldsInput } from "./trigger-step-fields-input";

export function TriggerStepFields(data) {
  const { name, fields, defaultResponse, integrationData = "" } = data;
  const { integrationType } = integrationData;

  function renderFields(data) {
    const { defaultValue, name, disableDataInsert } = data;

    bearlog.lug(`___ fieldRemove ___`, data);
    switch (integrationType) {
      case "sheets":
        const list = [name, defaultValue];

        const itemConfig = {
          align: "center",
          style: { width: mobView ? "40vw" : "15vw" },
          className: "shadowMain buttonHover wrap",
        };

        return BearList({
          style: { paddingTop: "20px" },
          list,
          horiz: true,
          itemConfig,
          spaceBetween: "20px",
          // renderItem: Input,
        });
        break;
      default:
        return TriggerStepFieldsInput(data);
    }
  }

  function renderItem(data) {
    return renderFields(data);
  }

  return BearList({
    list: fields,
    spaceBetween: "30px",
    renderItem,
  });
}
