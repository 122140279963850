import React from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearBalance,
  BearFloat,
  BearDivMain,
  BearColors,
} from "@sillyscribe95/bear-ui-web";
import { DashboardTypeMain } from "../dashboard/dashboard-type-main";
import { useHistory } from "react-router-dom";
import { inputUserData } from "./input-user-data";
import { AiOutlineEdit } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ItemCase } from "../../components/sidebar/item-case";
import { ItemList } from "../../components/sidebar/item-list";
import { TriggerStepOptionButton } from "../triggers/trigger-step-option-button";

export function InputMain() {
  const list = inputUserData;
  const history = useHistory();

  function visitFunc({ slug }) {
    history.push(`/inputs/${slug}`);
  }

  function customRender({ left, ...data }) {
    const { name, slug, numberOfResponses, description } = data;

    function ediFunc() {
      history.push(`/inputs/${slug}`);
    }

    const basic = BearDivMain(ItemCase(data), {
      onClick: () => visitFunc({ slug }),
      className: "shadowMain buttonHover",
    });

    const editBase = BearDivMain(AiOutlineEdit(), {
      className: "shadowMain buttonHover",
      onClick: ediFunc,
      padding: "50px",
    });

    const threeeall = BearDivMain(BsThreeDotsVertical(), {
      //
    });

    const blao = BearBalance(
      //
      "",
      // editBase
      threeeall
    );

    let right =
      //
      TriggerStepOptionButton(data);

    const akdwe = BearDivMain(numberOfResponses, {
      fontSize: "0.9em",
      // ...BearColors("white", "darkblue", "50%"),
    });

    right = BearBalance(akdwe, right);

    // blao

    // left = BearBalance(data.emoji, left);

    return BearFloat({
      left,
      // center,
      right,
      vertAlign: "",
      // className: "shadowMain buttonHover",
    });
  }

  const itemConfig = {
    // background: "blue",
  };

  const spaceBetween = "20px";

  const okfdsd = ItemList({
    list,
    config: {
      customRender,
      includeDescription: true,
    },
    click: visitFunc,
    // itemConfig,
    spaceBetween,
  });

  return DashboardTypeMain("inputs", okfdsd);
}
