import React from "react";
import { bearlog, BearDiv } from "@sillyscribe95/bear-ui-web";
import { InputMain } from "./input-main";
import { InputNewContainer } from "./input-new-container";
import { InputPreviewContainer } from "./input-preview-container";
import { InputEditContainer } from "./input-edit-container";
import { InputResponsesContainer } from "./input-responses-container";
import { InputFormContainer } from "./input-form-container";

export function InputPage(aaaaa) {
  const {
    //
    match: { params },
    ...args
  } = aaaaa;

  const { id, option } = params;

  bearlog.lug(`___ params ___`, params);
  const newtrue = id == "new";
  const repsonsetrue = option == "responses";
  const editTrue = option == "edit";
  const testTrue = option == "test";

  bearlog.lug(`___ inputPage ___`, { option, repsonsetrue, editTrue });

  function duno() {
    const eso =
      //
      //   InputMain;z
      newtrue
        ? InputNewContainer
        : id
        ? editTrue
          ? InputEditContainer
          : repsonsetrue
          ? InputResponsesContainer
          : InputFormContainer
        : InputMain;

    return eso({
      id,
      option,
    });
  }

  return duno();
}
