import { SiPaypal, SiStripe } from "react-icons/si";

const gcalendar = {
  // icon
  name: "Google Calendar",
  description:
    "Google Calendar lets you organize your schedule and share events with co-workers and friends. With Google's free online calendar, it's easy to keep track of your daily schedule.",
  numTriggers: 4,
  numActions: 20,
  // redirect:
};

const calendly = {
  name: "Calendly",
  // icon;
  description:
    "Calendly is an elegant and simple scheduling tool for businesses that eliminates email back and forth. It helps save time so that businesses can provide great service and increase sales.",
  // apiPoint: ""
  numTriggers: 2,
  numActions: 10,
};

export const optionsCalendarData = {
  gcalendar,
  calendly,
};
