import React from "react";
import {
  bearlog,
  BearDiv,
  BearDivMain,
  BearFloat,
  BearTitle,
  BearBalance,
} from "@sillyscribe95/bear-ui-web";
import { BsAlignStart } from "react-icons/bs";

export function ItemCase(
  {
    //
    customRender,
    includeDescription,
    renderIntegration,
    ...data
  },
  ext
) {
  const {
    name,
    emoji,
    cover,
    description,
    noIcon,
    integrationData = "",
  } = data;
  const { icon } = integrationData;

  const nameBase = BearDivMain(`${emoji} ${name}`, {
    className: "wrap",
  });

  // left = BearBalance(
  //   BearDivMain(icon, {
  //     className: "shadowMain",
  //     style: { marginRight: "30px" },
  //     fontSize: "1.3em",
  //   }),
  //   name
  // );

  // 1integration
  function interBase({ icon, type, trigger }) {
    let res = trigger ? BsAlignStart() : icon;
    // res = BearDivMain(res, type, {
    //   className: "wrapTwo",
    //   width: "100px",
    // });

    return res;

    // renderIntegration ? renderIntegration(children)
  }

  const integrateBase = renderIntegration
    ? renderIntegration(data)
    : integrationData && interBase(integrationData);

  const title =
    //
    // nameBase;
    nameBase;
  // `${emoji} ${nameBase}`;
  // BearBalance(emoji, nameBase);

  const subtitle =
    includeDescription &&
    BearDivMain(description, {
      className: "wrapTwo",
    });

  const left = subtitle
    ? title
    : BearTitle({
        title,
        subtitle,
        align: "left",
      });

  return customRender
    ? customRender({
        left,
        ...data,
      })
    : BearDivMain(left, ext);
}
