import React, { useState } from "react";
import {
  bearlog,
  BearDiv,
  BearFloat,
  BearDivMain,
  BearList,
  BearBalance,
  BearTitle,
  BearLine,
} from "@sillyscribe95/bear-ui-web";
import { Divider, Input, Popover } from "antd";
import { TriggersContext } from "./context/triggers-context";
import { backColor, mobView } from "../../const/main-const";
import { TriggerStepOptionButton } from "./trigger-step-option-button";
import { TriggerStepFields } from "./trigger-step-fields";
import {
  TriggerStepMiddle,
  TriggerStepMiddleTest,
} from "./trigger-step-middle";
import { useContext } from "react";
import { TriggerStepChooseContainer } from "./trigger-step-choose-container";
import { TriggerStepChooseFunction } from "./trigger-step-choose-function";
import { TriggerStepChooseIntegration } from "./trigger-step-choose-integration";
import { TriggerStepChooseType } from "./trigger-step-choose-type";
import { FaQuestion } from "react-icons/fa";
import { TriggerStepPanelComponent } from "../integrations/components/trigger-step-panel-component";

export function TriggerPathEdit(data, renderContainer) {
  // 1const
  const { fields, integrationData = "" } = data;
  const { currentAccount, icon, integrationType } = integrationData;
  const setAccount = currentAccount;
  // const [setAccount, setsetAccount.] = useState(currentAccount);

  const { stepViewType } = useContext(TriggersContext);

  bearlog.lug(`___ TrierPathEdit ___`, { integrationData });

  //   1integrtaion
  function renderIntegration() {
    const { icon, name, accounts } = integrationData;

    function click(data) {
      bearlog.lug(`___ click integration dATA ___`, data);

      // setsetAccount();
    }

    const content = BearList({
      list: accounts,
      click,
      spaceBetween: "15px",
      itemConfig: { className: "shadowMain buttonHover" },
    });

    const children = BearBalance(currentAccount, icon, {
      className: "shadowMain buttonHover",
    });

    const popConfig = {
      placement: "bottom",
      children,
      content,
    };

    const title = <Popover {...popConfig} />;

    const endia = BearDivMain(title, {
      vertAlign: false,
      fontSize: "18px",
    });

    return endia;
  }

  const title = TriggerStepPanelComponent(data, {
    className: "shadowMain",
  });

  const kndsq = BearDivMain(renderContainer(data), {
    className: "shadowMain",
    style: { padding: "20px" },
  });

  const expandMain = BearDivMain(
    <>
      {title}
      {/* {BearLine("20px")} */}
      {kndsq}
    </>,
    {
      // className: "shadowMain",
    }
  );

  const okfdsd = expandMain;

  return okfdsd;
}
