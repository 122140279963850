import React from "react";
import {
  bearlog,
  BearDiv,
  BearFloat,
  BearDivMain,
  BearList,
  BearBalance,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";

export function AddButton({ disabled, ...ext }) {
  const className = disabled ? "shadowMain disabled" : "shadowMain buttonHover";

  let center = BearDivMain(" + ", {
    style: { padding: "0px 20px" },
    className,
    ...ext,
  });

  return BearFloat({
    center,
    style: {
      margin: "5vh 0",
    },
  });
}
