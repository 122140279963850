import React from "react";
import {
  bearlog,
  BearDiv,
  BearDivMain,
  BearList,
} from "@sillyscribe95/bear-ui-web";
import { TriggerPathEntry } from "./trigger-path-entry";
import { ItemCase } from "../../components/sidebar/item-case";
import { TriggerStepPanelComponent } from "../integrations/components/trigger-step-panel-component";

export function TriggerEditInput(data) {
  const { name: DataName, formData, input } = data;

  const name = `Input: ${DataName}`;
  // const right = formData && BearDivMain()

  console.log(`___ loggo data ___`, data);

  function editBase(data) {
    return "";
  }

  return TriggerPathEntry(
    {
      ...data,
      name,
    },
    editBase
  );
}
