import React, { useContext } from "react";
import {
  bearlog,
  BearDiv,
  BearBalance,
  BearFloat,
  BearDivMain,
  BearTitle,
  BearList,
} from "@sillyscribe95/bear-ui-web";
import { TriggerStepChooseIntegration } from "./trigger-step-choose-integration";
import { TriggerStepChooseFunction } from "./trigger-step-choose-function";
import { AiOutlineAppstore } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { TriggersContext } from "./context/triggers-context";
import { TriggerStepChooseType } from "./trigger-step-choose-type";
import { backIcon } from "../../const/main-const";

export function TriggerStepChooseContainer(
  subtitle,
  name,
  { right, backType }
) {
  const { setstepViewType } = useContext(TriggersContext);

  const back =
    backType &&
    BearDivMain(backIcon, {
      onClick: () => setstepViewType("main"),
      className: "shadowMain buttonHover",
    });

  const left = BearBalance(back, name);

  const title = BearFloat({
    left,
    right,
  });

  return name
    ? BearTitle({
        fontSize: "0.7em",
        title,
        subtitle,
        lineBetween: true,
        spaceBetween: "20px",
      })
    : subtitle;
}
