import { bearMobileView } from "@sillyscribe95/bear-ui-web";
import { BsAlignStart } from "react-icons/bs";
import { FaQuestion, FaUser } from "react-icons/fa";
import { GiTrigger } from "react-icons/gi";
import { IoMdArrowBack } from "react-icons/io";
import { GoLinkExternal } from "react-icons/go";
import { MdOutlineFunctions } from "react-icons/md";

export const mobView =
  //
  bearMobileView;

export const triggiIcon =
  //
  // "*";
  BsAlignStart();

export const backIcon =
  //
  IoMdArrowBack();
// IoArrowBackOutline();

export const userIcon = FaUser();

export const iconTriggi = BsAlignStart();

export const backColor =
  //
  "#f7f6f3";

// 1icon
export const functionIcon = MdOutlineFunctions();
export const newStepIcon = FaQuestion();

export const newStepType = "New";

export const linkExtIcon = GoLinkExternal();

export const testAppTrue =
  //
  // ""
  "asdokas";
