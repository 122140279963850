import { BsPersonCheck } from "react-icons/bs";
import { MdOutlineBusinessCenter } from "react-icons/md";
import { triggiIcon } from "../../const/main-const";
import { optionSheetsData } from "../integrations/data/integrations-options-sheets-data";

const respsonseDatabase = {
  integrationData: optionSheetsData["gsheets"],
  sheetInfo: {
    name: "Responses - New Client",
    link:
      "https://docs.google.com/spreadsheets/d/1_LHvHDJbRzFuhPaX5GWKlakybthQPKcyKDK-bEA6nuY/edit?usp=sharing",
  },
};

const testClient = {
  email: "andrew@test.com",
  name: "Andrew Maguire",
  createdDate: "2 days ago",
};

export const newClientInput = {
  name: "New Client",
  id: "asdokfeww",
  description: "Enter a new client in the database if a sales call goes well.",
  slug: "new-client",
  emoji: "🧍",
  respsonseDatabase,
  numberOfResponses: 5,
  defaultResponse: testClient,
  responseData: [
    testClient,
    {
      email: "another@test.com",
      name: "Another User",
      createdDate: "1 week ago",
    },
  ],
  fields: [
    {
      id: "xcvbodfaq",
      key: "Name",
      value: "Andrew Maguire",
    },
    {
      id: "129234781",
      key: "Email",
      value: "andrew@gmail.com",
    },
  ],
  integrationData: {
    type: "Custom",
    icon: triggiIcon,
  },
};

export const newVendorInput = {
  name: "New Software Vendor",
  id: "r9eweqq",
  emoji: "💼",
  numberOfResponses: 2,
  description:
    "Enter a new vendor in our database if a partnership call goes well.",
  slug: "new-software-vendor",
  fields: [
    {
      id: "bfdereads",
      key: "Name",
      value: "First Last",
    },
    {
      id: "3459uwe97",
      key: "Email",
      value: "text@gmail.com",
    },
  ],
};

export const testInput = newClientInput;
export const inputUserData = [
  //
  newClientInput,
  newVendorInput,
];
