import React from "react";
import { bearlog, BearDiv, BearDivMain } from "@sillyscribe95/bear-ui-web";
import { useQuery } from "react-query";
import axios from "axios";
import { BearStripeOpenCheckout } from "../../bear/page/bear-stripe-open-checkout";
import { loadStripe } from "@stripe/stripe-js";
import { gapi } from "gapi-script";
import GoogleLogin from "react-google-login";
import { openIntegrationPopup } from "../containers/integrations/functions/open-integration-popup";

export function TestPage() {
  // 1google
  const client_id = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

  function doGoogle() {
    function start() {
      bearlog.lug(`___ clientId ___`, client_id);

      gapi.client.init({
        client_id,
        scope: "",
      });

      bearlog.lug(`___ gapi ___`, gapi);
    }

    gapi.laod("client:auth2", start);
  }

  // 1popup
  async function popupBase() {
    const linkBase =
      "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_LidnVChWUvpIzYYhWFbJrLzXmQ0Jb0Ef&scope=read_write";

    openIntegrationPopup(linkBase);
  }

  // 1stripe
  async function gettos(linke) {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_testpubKey);

    const link = `/.netlify/functions/stripe-checkout-session-api`;
    const { data } = await axios.get(link);
    const { id: sessionId, url } = data;

    bearlog.lug(`___ aioxs data ___`, data);

    window.location.href = url;
    // stripe.redirectToCheckout({ sessionId });

    // setuserlist(data);

    return data;
  }

  const { data, loading } = useQuery("asdok", popupBase);

  const glasds = (
    <GoogleLogin
      //
      clientId={client_id}
      buttonText="Do it"
      cookiePolicy={"single_host_origin"}
    />
  );

  return BearDivMain(
    //
    "CLCIK BASE",
    // glasds,
    {
      // onClick: kinksa,
      className: "shadowMain buttonHover",
    }
  );
}
