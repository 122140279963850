import React, { useContext } from "react";
import {
  bearlog,
  BearDiv,
  BearFloat,
  BearDivMain,
  BearList,
  BearBalance,
  BearTitle,
} from "@sillyscribe95/bear-ui-web";
import { IoMdArrowDropdown } from "react-icons/io";
import { Popover } from "antd";
import { TriggersContext } from "./context/triggers-context";
import { render } from "@testing-library/react";

export function TriggerStepFieldInsertData(data) {
  const { triggerData } = useContext(TriggersContext);
  const { input } = triggerData;
  const { name, defaultResponse, integrationData } = input;
  const { icon } = integrationData;

  const title = BearBalance(icon, name, {
    align: "left",
  });

  const list = Object.entries(defaultResponse);

  function click(dasta) {
    bearlog.lug(`___ dasta ___`, dasta);
  }

  function renderItem([key, value]) {
    // bearlog.lug(`___ teis ___`, teis);

    const leftios = BearDivMain(`${key}:`, {
      style: { fontWeight: "bold" },
      fontSize: "1.3em",
    });

    const rigias = value;

    return BearBalance(leftios, rigias, {
      align: "left",
    });
  }

  const itemConfig = {
    textAlign: "left",
    className: "shadowMain buttonHover",
  };

  const subtitle = BearList({
    itemConfig,
    list,
    renderItem,
    click,
  });

  const content = BearTitle({
    title,
    subtitle,
    align: "left",
    lineBetween: true,
  });

  const children = BearDivMain(IoMdArrowDropdown(), {
    style: { padding: "5px 10px" },
    className: "shadowMain buttonHover",
  });

  const popConfig = {
    children,
    content,
    placement: "bottom",
  };

  return <Popover {...popConfig} />;
}
