import React, { useState } from "react";
import {
  bearlog,
  BearDiv,
  BearList,
  BearTitle,
  BearDivMain,
  BearColors,
  BearFloat,
  capitalizeFirstLetter,
} from "@sillyscribe95/bear-ui-web";
import { openIntegrationPopup } from "../integrations/functions/open-integration-popup";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";

export function SettingsBilling() {
  const title = "You're on our Starter Plan.";

  const [loadBase, setloadBase] = useState("Manage Plan");

  async function onClick() {
    setloadBase("Loading...");
    const link = `/.netlify/functions/stripe-checkout-session-api`;

    const return_url = window.location.href;
    const params = {
      return_url,
    };

    bearlog.lug(`___ params ___`, params);

    const { data } = await axios.get(link, {
      params,
    });
    const { id: sessionId, url } = data;

    setloadBase("Manage Plan");

    window.location.href = url;
    // openIntegrationPopup(url);
  }

  const teixo =
    //
    loadBase;
  // loadBase ? "Loading..." : "Manage Plan";

  const subtitle = BearDivMain(teixo, {
    fontSize: "24px",
    style: BearColors("white", "darkblue"),
    onClick,
    align: "center",
    className: "buttonHover expandHover",
  });

  const center = BearTitle({
    title,
    spaceBetween: "20px",
    subtitle,
  });

  return BearFloat({
    center,
  });
}
