import React, { useContext } from "react";
import { bearlog, BearDiv, BearList } from "@sillyscribe95/bear-ui-web";
import { inputUserData } from "../inputs/input-user-data";
import { ItemCase } from "../../components/sidebar/item-case";
import { ItemList } from "../../components/sidebar/item-list";
import { TriggersContext } from "./context/triggers-context";

export function TriggerInputInternalOptions(data) {
  const { settriggerData } = useContext(TriggersContext);

  function click(input) {
    bearlog.lug(`___ tInternlOptions CLICK ___`, input);
    settriggerData({ input });
  }

  const list = inputUserData;
  const okfdsd = ItemList({
    list,
    renderItem: ItemCase,
    click,
  });

  return okfdsd;
}
