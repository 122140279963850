import React from "react";
import {
  bearlog,
  BearDiv,
  BearBalance,
  BearDivMain,
} from "@sillyscribe95/bear-ui-web";
import { DashboardPage } from "../dashboard-page";
import { newClientInput } from "./input-user-data";
import { BsFillPersonFill } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { InputContainerMain } from "./input-container-main";
import { InputForm } from "./input-form";

export function InputFormContainer({ id, option }) {
  const inputData = newClientInput;
  const okfdsd = InputForm(inputData);

  return InputContainerMain(okfdsd, "");
}
