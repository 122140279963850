import React, { useContext } from "react";
import { bearlog, BearDiv, BearDivMain } from "@sillyscribe95/bear-ui-web";
import { DashboardPage } from "../dashboard-page";
import { useHistory } from "react-router-dom";
import { TriggersContext } from "./context/triggers-context";

export function TriggerMainContainer(id, main) {
  const history = useHistory();
  const { triggerData, settriggerData } = useContext(TriggersContext);
  const { name } = triggerData;

  function renids(tyos, mians) {
    function onClick() {
      history.push(`/triggers/${id}/${tyos}`);
    }

    return BearDivMain(mians, {
      onClick,
      className: "shadowMain buttonHover",
    });
  }

  //   const

  const right = renids("test", "Test");

  const titleConfig = {
    right,
  };

  return DashboardPage("triggers", main, {
    titleConfig,
    title: name,
    // backTrue: true,
    // fixTitle: true,
  });
}
